import { limeApiGet } from "./api";
import { AuthContextProps } from "react-oidc-context";

export class FeatureFlagsResult {
    directDebitEnabled: boolean;

    constructor(DirectDebitEnabled: boolean = false) {
        this.directDebitEnabled = DirectDebitEnabled;
    }
}

export async function getFeatureFlag(auth: AuthContextProps){
    if (!auth.isLoading && auth.isAuthenticated) {
        return await limeApiGet<FeatureFlagsResult>(`feature`, auth);
    }
    return new FeatureFlagsResult();
}