import React, { useCallback, useEffect, useRef, useState } from 'react';
import Settings from "../../../images/settings.svg";

interface Props {
  close: (callback: () => void) => void;
  children?: React.ReactNode;
}

function DropdownMenu({close, children}: Props) {
  const [display, setDisplay] = useState(false);
  let cogRef = useRef(null);
  let menuRef = useRef(null);

  const onClose = useCallback((): void => {
    setDisplay(false);
  }, []);

  useEffect(() => {
    close && close(onClose);
  }, [close, onClose]);

  useEffect(() => {
    const onClick = (e: any) => {
      if (display &&
        cogRef &&
        cogRef.current &&
        e.target !== cogRef.current &&
        menuRef &&
        menuRef.current &&
        // @ts-ignore
        !menuRef.current.contains(e.target)) {
        setDisplay(false);
      }
    };
    document.addEventListener("click", onClick);

    return () => {
      document.removeEventListener("click", onClick);
    }
  }, [display, cogRef, menuRef]);

  return (
    <div className="relative">
      <img ref={cogRef} src={Settings} alt="Settings" className="cursor-pointer" onClick={() => setDisplay(!display)}/>
      {display && <div ref={menuRef}
        className={`absolute bg-pure-white p-4 border-solid border border-primary-text rounded-xl z-10 shadow h-auto w-56`}>
        {children}
      </div>}
    </div>
  )
}

export default DropdownMenu;
