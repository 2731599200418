import React, { useState } from 'react';
import { useAuth } from "react-oidc-context"
import { getClaimValue } from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import { composeUrl } from "../../utils/api";
import OnboardingTaskItem from "./OnboardingTaskItem";
import { OnboardingStatus, OnboardingTask } from "./types";
import { formatViewDate } from "../../utils/dateFormat";

function OnboardingDashboard() {
  const auth = useAuth();
  const [name, setName] = useState<string | undefined>();
  const [startDate, setStartDate] = useState<string | undefined>();
  const [tasks, setTasks] = useState<OnboardingTask[]>([]);

  React.useEffect(() => {
    setName(getClaimValue(auth.user, "nickname"));

    const orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
    fetch(composeUrl(`organisations/${orgUuid}/onboarding`),
      {
        headers: [['Authorization', `Bearer ${auth.user?.access_token}`]],
        method: 'GET'
      })
      .then(response => response.json() as Promise<OnboardingStatus>)
      .then(data => {
        const date = formatViewDate(new Date(data.startDate));
        setStartDate(date);
        setTasks(data.tasks);
      } );
  }, [auth.user]);

  return (
      <div className="max-w-xl">
        <h1>Hello {name}</h1>
        <p className="my-4 text-h4 font-normal">Your organisation must complete the tasks below before <b>{startDate}</b> to get your organisation started</p>
        <div>
          <h4>For you</h4>
          <ul className="my-4 p-4 bg-super-light-grey rounded-xl">
            { tasks.length > 0 && tasks.filter(task => task.isAllowed).map((task, index) =>
              <OnboardingTaskItem key={index} task={task} />
            )}
          </ul>
        </div>

        { tasks.length > 0 && tasks.filter(task => !task.isAllowed).length > 0 && <div>
            <h4>For others</h4>
            <ul className="my-4 p-4 bg-super-light-grey rounded-xl">
              { tasks.length > 0 && tasks.filter(task => !task.isAllowed).map((task, index) =>
                <OnboardingTaskItem key={index} task={task} />
              )}
            </ul>
          </div>
        }
      </div>
    );
}

export default OnboardingDashboard;
