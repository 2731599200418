import React from "react";

interface Props {
  title: string;
  children?: JSX.Element;
  okLabel: string;
  okClick: () => void;
  cancelLabel: string;
  cancelClick: () => void;
  show: boolean;
  errorMessage?: string;
  important?: boolean;
  isLoading?: boolean;
}

function OkCancelModal(props: Props) {
  return(
      <div className={`fixed top-0 left-0 z-50 h-full w-full bg-dark-grey/[.5] px-md${props.show ? '' :  ' hidden'}`}>
        <div className="bg-pure-white top-0 left-0 p-xl sm:p-2xl max-w-[40rem] mx-auto mt-2xl rounded-md text-center">
          <h4 className="mb-md">{ props.title }</h4>
          {props.children}
          <p className={`mb-md text-error-red${props.errorMessage ? '' : ' hidden'}`}>
            {props.errorMessage}
          </p>
          <div className="flex flex-col gap-sm sm:flex-row items-center sm:justify-center">
            <button onClick={props.cancelClick} className="bg-pure-white border-solid border border-primary-text rounded-full text-primary-text font-sans-co" disabled={props.isLoading}>
              {props.cancelLabel}
            </button>
            <button className={`font-sans-co ${props.important ? 'bg-error-red text-pure-white' : ''}`} onClick={props.okClick} disabled={props.isLoading}>
              {props.okLabel}
            </button>
          </div>
        </div>
      </div>
  );
}

export default OkCancelModal;