import React, { useState } from 'react';
import { useAuth } from "react-oidc-context";
import { getClaimValue } from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import { composeUrl } from "../../utils/api";
import OkCancelModal from "../Shared/Modal/OkCancelModal";
import { useForm } from "react-hook-form";
import { User } from "./types";

interface Props {
  user?: User,
  isOpen: boolean,
  setIsOpen: (value: boolean) => void,
  onModifyRoles: () => void
}

type FormData = {
  memberManager: boolean;
  billingManager: boolean;
  userAdministrator: boolean;
};

function ModifyUserRoles({user, isOpen, setIsOpen, onModifyRoles}: Props) {
  const auth = useAuth();
  const [error, setError] = useState<string>();
  const {register, reset, handleSubmit, getValues, setValue, formState: {isDirty, errors}} = useForm<FormData>({mode: "onBlur", reValidateMode: "onBlur"});

  React.useEffect(() => {
    setValue("memberManager", user?.roles.indexOf("Organisation Member Manager") !== -1);
    setValue("billingManager", user?.roles.indexOf("Organisation Billing Manager") !== -1);
    setValue("userAdministrator", user?.roles.indexOf("Organisation User Administrator") !== -1);
  }, [user, setValue]);

  const onSubmit = async (data: FormData) => {
    if(isDirty) {

      let request = {
        roles: new Array<string>()
      };
      if(data.memberManager) {
        request.roles.push("Organisation Member Manager");
      }
      if(data.billingManager) {
        request.roles.push("Organisation Billing Manager");
      }
      if(data.userAdministrator) {
        request.roles.push("Organisation User Administrator");
      }

      let orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
      const response = await fetch(composeUrl(`organisations/${orgUuid}/administrators/${user?.id}/roles`),
      {
        headers: [[ 'Authorization',  `Bearer ${auth.user?.access_token}` ], [ 'Content-Type', 'application/json' ]],
        method: 'PUT',
        body: JSON.stringify(request)
      });

      if (response.ok) {
        close();
        onModifyRoles();
      } else {
        const responseBody = await response.json();
        if (responseBody.hasOwnProperty('code') && responseBody.hasOwnProperty('message')) {
          setError(responseBody.message);
        } else {
          setError("Sorry an error has occurred");
        }
      }
    } else {
      close();
      onModifyRoles();
    }
  }

  function close() {
    reset();
    setError("");
    setIsOpen(false);
  }

  function validateRoles() {
    const data = getValues();
    return data.memberManager || data.billingManager || data.userAdministrator;
  }

  return (
    <OkCancelModal
      title="Modify Roles"
      okLabel="Apply"
      okClick={handleSubmit(onSubmit)}
      cancelLabel="Cancel"
      cancelClick={close}
      show={isOpen}
      errorMessage={error}>
      <div className="mb-xl w-96 text-left mb-2 mx-auto">
        <form>
          <div className="mb-2">
            <span className="text-p1 font-bold mb-2 mr-8">User</span>
            <span>{`${user?.firstName} ${user?.lastName}`}</span>
          </div>
          <h4 className="mb-2">Roles</h4>
          <div>
            <input type="checkbox" {...register("memberManager", {required: false, validate: validateRoles })} />
            <span className="ml-4 text-p1 font-bold">Member Manager</span>
          </div>
          <div>
            <input type="checkbox" {...register("billingManager", {required: false, validate: validateRoles })} />
            <span className="ml-4 text-p1 font-bold">Billing Manager</span>
          </div>
          <div>
            <input type="checkbox" {...register("userAdministrator", {required: false, validate: validateRoles })} />
            <span className="ml-4 text-p1 font-bold">User Administrator</span>
          </div>
          {(errors.memberManager || errors.billingManager || errors.userAdministrator) && <p className="mt-1 text-p2 text-error-red">At least one role must be selected</p>}
        </form>
      </div>
    </OkCancelModal>
  );
}

export default ModifyUserRoles;