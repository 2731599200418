import React from 'react';
import { NavLink } from "react-router-dom";

interface Props {
  route: string;
  title: string;
  icon: string;
  activeIcon: string;
  visible?: boolean;
}

function NavBarItem({route, title, icon, activeIcon, visible = true}: Props) {
  return (
    <li className={visible ? "" : "hidden"}>
      <NavLink
        to={route}
        className={({isActive}) =>
          isActive
            ? "flex w-fit sm:w-full block px-sm py-xs sm:py-sm mb-md font-bold font-sans-co hover:no-underline rounded-md sm:rounded-lg text-pure-white visited:text-pure-white active:text-pure-white bg-dark-grey border-solid border-4 border-pure-white"
            : "flex w-fit sm:w-full block px-sm py-xs sm:py-sm mb-md font-bold font-sans-co hover:no-underline rounded-md sm:rounded-lg text-primary-text visited:text-primary-text active:text-primary-text bg-pure-white hover:bg-super-light-grey border-solid border-4 border-pure-white"
        }
        children={(x) => (
          <>
            <img src={x.isActive ? activeIcon : icon} alt={title} className="hidden sm:block sm:mr-md sm:h-md sm:mt-1"/>
            {title}
          </>
        )}
      />
    </li>
  );
}

export default NavBarItem;