import React, {useState} from 'react';
import {Tab, TabContent, Tabs} from "../Shared/Tabs";
import {formatViewDateLong} from "../../utils/dateFormat";
import {getClaimValue, hasRoleClaim} from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import {useAuth} from "react-oidc-context";
import {limeApiFetch, limeApiGet} from "../../utils/api";
import AlertCircle from "../../images/alert-circle.svg";
import {useNavigate} from "react-router-dom";
import WellKnownRoles from "../../models/wellKnownRoles";
import LoadingSpinner from "../Shared/LoadingSpinner/LoadingSpinner";
import { Invoice, LimePaymentMethod, Payment, PaymentMethodStatus, PaymentTypeId } from "./types";
import PaymentFailureBanner from "./PaymentFailureBanner";
import { LimeConfig } from "../../utils/limeConfig";
import {loadStripe, Stripe} from "@stripe/stripe-js";
import { getCurrentUserPermissions } from "../../services/permissionsService";


import downloadIcon from "../../images/download-dark.svg";
import {saveAs} from "file-saver";

function Billing() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [tabsLoading, setTabsLoading] = useState<boolean>(true);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [paymentMethodStatus, setPaymentMethodStatus] = useState<PaymentMethodStatus>(PaymentMethodStatus.Loading);
  const [paymentMethod, setPaymentMethod] = useState<LimePaymentMethod>();
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [hasManagePaymentMethodsPermission, setHasManagePaymentMethodsPermission] = useState(false);

  React.useEffect(() => {
    (async () => {
      if (!hasRoleClaim(auth.user, WellKnownRoles.OrganisationBillingViewer) &&
        !hasRoleClaim(auth.user, WellKnownRoles.OrganisationBillingManager)) {
        navigate('/access-denied');
      }
      const config = await limeApiGet<LimeConfig>('configuration', auth);
      setStripe(await loadStripe(config.stripeApiKey));
      await refreshTabs();
      await refreshPaymentMethods();
      await refreshPermissions();
    })();
  }, []);

  const navigateToAddPayment = () => {
    navigate('/billing/payment', { state: {updatePaymentMethod: !!paymentMethod}});
  }

  async function refreshTabs() {
    setTabsLoading(true);
    await refreshData();
    setTabsLoading(false);
  }

  async function refreshData() {
    const orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
    const invoices = await limeApiGet<Invoice[]>(`organisations/${orgUuid}/invoices`, auth);
    setInvoices(invoices);
    const payments = await limeApiGet<Payment[]>(`organisations/${orgUuid}/payments`, auth);
    setPayments(payments);
  }

  async function refreshPaymentMethods() {
    setPaymentMethodStatus(PaymentMethodStatus.Loading);
    let orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
    let response = await limeApiFetch(`organisations/${orgUuid}/paymentMethods`, 'GET', auth);
    if (response.status === 200) {
      let data = await response.json();
      setPaymentMethod(data);
      setPaymentMethodStatus(PaymentMethodStatus.Active);
    } else {
      if (response.status === 404) {
        let data = await response.json();
        if (data.code === "no_payment_method") {
          setPaymentMethodStatus(PaymentMethodStatus.Required)
        } else {
          setPaymentMethodStatus(PaymentMethodStatus.Error);
        }
      } else {
        setPaymentMethodStatus(PaymentMethodStatus.Error);
      }
    }
  }

  async function refreshPermissions() {
    const permissions = await getCurrentUserPermissions(auth);
    const hasPermission = permissions.includes("microservice-organisation-billing.ManagePaymentMethods");
    setHasManagePaymentMethodsPermission(hasPermission);
  }

  async function downloadInvoice(invoiceId: number, invoiceRef: string) {
    setTabsLoading(true);
    let orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
    let response = await limeApiFetch(`organisations/${orgUuid}/invoices/${invoiceId}/download`, 'GET', auth);
    if (response.status === 200) {
      let blob = await response.blob();
      saveAs(blob, invoiceRef);
    }

    setTabsLoading(false);
  }

  function padValue(value: number): string {
    return value < 10 ? "0" + value.toString() : value.toString();
  }

  return (
    <div>
      <PaymentFailureBanner stripe={stripe} onPaymentProcessed={refreshData} />
      <h1 className="mb-md">Billing</h1>
      <p className="text-p1 mb-lg">Manage your organisations invoices, payments and payment method</p>
      <LoadingSpinner loading={tabsLoading}>
        <Tabs>
        <Tab tabKey="1" name="Invoices"/>
        <Tab tabKey="2" name="Payments"/>
        <TabContent associatedTabKey="1">
          <table className="w-full">
            <thead className="bg-super-light-grey">
            <tr className="h-2xl text-left text-primary-text">
              <th className="px-md py-sm border-b-2 border-primary-text rounded-l-md">Ref</th>
              <th className="px-md py-sm border-b-2 border-primary-text">Date</th>
              <th className="px-md py-sm border-b-2 border-primary-text">Services</th>
              <th className="px-md py-sm border-b-2 border-primary-text">Amount</th>
              <th className="px-md py-sm border-b-2 border-primary-text">Status</th>
              <th className="px-md py-sm border-b-2 border-primary-text rounded-r-md"/>
            </tr>
            </thead>
            <tbody>
            {
              invoices && invoices.length > 0 &&
              invoices.map((inv, i) =>
                <tr key={i} className="text-left bg-pure-white even:bg-super-light-grey">
                  <td className="px-md py-sm border-y-2 border-background-blu rounded-l-md">
                    {inv.ref}
                  </td>
                  <td className="px-md py-sm border-y-2 border-background-blu">
                    {formatViewDateLong(inv.date)}
                  </td>
                  <td className="px-md py-sm border-y-2 border-background-blu">
                    {inv.products.map(p => p.name.trim()).join(', ')}
                  </td>
                  <td className="px-md py-sm border-y-2 border-background-blu">
                    {inv.total.toLocaleString('en-GB', {
                      style: 'currency',
                      currency: 'GBP'})}
                  </td>
                  <td className="px-md py-sm border-y-2 border-background-blu">
                    {inv.statusName}
                  </td>
                  <td className="px-md py-sm border-y-2 border-background-blu rounded-r-md">
                    <img
                      src={downloadIcon}
                      onClick={() => downloadInvoice(inv.id, inv.ref)}
                      alt="Download_Icon"
                      className="h-lg hover:cursor-pointer"/>
                  </td>
                </tr>)
            }
            </tbody>
          </table>
        </TabContent>
        <TabContent associatedTabKey="2">
          <table className="w-full">
            <thead className="bg-super-light-grey">
              <tr className="h-2xl text-left text-primary-text">
                <th className="px-md py-sm border-b-2 border-primary-text rounded-l-md">Date</th>
                <th className="px-md py-sm border-b-2 border-primary-text">Amount</th>
                <th className="px-md py-sm border-b-2 border-primary-text">Payment Method</th>
                <th className="px-md py-sm border-b-2 border-primary-text">Status</th>
                <th className="px-md py-sm border-b-2 border-primary-text rounded-r-md">Invoices</th>
              </tr>
            </thead>
            <tbody>
            {
              (!payments || payments.length == 0) &&
                <tr>
                    <td colSpan={5} className="p-sm"><b>No payments have been made yet</b></td>
                </tr>
            }
            {
              payments && payments.length > 0 &&
              payments.map((p, i) =>
                <tr key={i} className="text-left bg-pure-white even:bg-super-light-grey">
                  <td className="px-md py-sm border-y-2 border-background-blu rounded-l-md">
                    {formatViewDateLong(p.date)}
                  </td>
                  <td className="px-md py-sm border-y-2 border-background-blu">
                    {p.amount.toLocaleString('en-GB', {
                      style: 'currency',
                      currency: 'GBP'})}
                  </td>
                  <td className="px-md py-sm border-y-2 border-background-blu">
                    {p.paymentMethodBrand} / {p.paymentMethodLast4Digits}
                  </td>
                  <td className="px-md py-sm border-y-2 border-background-blu">
                    {p.typeId == PaymentTypeId.Charge ? p.statusName : "Refunded"}
                  </td>
                  <td className="px-md py-sm border-y-2 border-background-blu rounded-r-md">
                    {p.invoices.map(i => i.ref).join(', ')}
                  </td>
                </tr>)
            }
            </tbody>
          </table>
        </TabContent>
      </Tabs>
      </LoadingSpinner>
      <h3 className="mb-md mt-xl">Payment Method</h3>
      <LoadingSpinner loading={paymentMethodStatus === PaymentMethodStatus.Loading}>
        <div className="bg-super-light-grey w-[24rem] p-xl rounded-md">
          {
            paymentMethodStatus === PaymentMethodStatus.Error &&
              <p className="font-bold text-error-red">Sorry an error occurred loading your payment method</p>
          }
          {
              paymentMethodStatus === PaymentMethodStatus.Required &&
              <>
                <div className={`flex ${hasManagePaymentMethodsPermission ? 'mb-lg' : ''}`}>
                  <img src={AlertCircle} className="h-lg" alt="alert"/>
                  <p className="ml-md font-bold">
                    {invoices && invoices.length > 0 ?
                        (hasManagePaymentMethodsPermission ? "Please setup a payment method" : "Payment method required") :
                        "Return to add a payment method after your first invoice is generated."}
                  </p>
                </div>
                {hasManagePaymentMethodsPermission && invoices && invoices.length > 0 &&
                    <button className="w-full" onClick={navigateToAddPayment}>Add</button>
                }
              </>
          }
          {
              paymentMethodStatus === PaymentMethodStatus.Active && paymentMethod &&
              <>
                <table className="mb-lg w-full">
                  <tbody>
                  <tr>
                    <td>Type</td>
                    <th className="text-right">{paymentMethod.brand}</th>
                  </tr>
                  <tr>
                    <td>{paymentMethod.brand === 'BACS' ? 'Acc last 4 Digits' : 'Card last 4 Digits'}</td>
                    <th className="text-right">{paymentMethod.last4Digits}</th>
                  </tr>
                  {paymentMethod.brand !== 'BACS' && (
                      <tr>
                        <td>Expiry</td>
                        <th className="text-right">
                          {`${padValue(paymentMethod.expiryMonth)}/${paymentMethod.expiryYear}`}
                        </th>
                      </tr>
                  )}
                  </tbody>
                </table>
                {hasManagePaymentMethodsPermission &&
                    <button className="w-full" onClick={navigateToAddPayment}>Modify</button>
                }
            </>
          }
        </div>
      </LoadingSpinner>
    </div>
  );
}

export default Billing;