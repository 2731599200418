import {AuthContextProps} from "react-oidc-context";

export function composeUrl(address: string): string {
  const baseUrl = absoluteUrl('api');
  if (address.startsWith('/')) {
    address = address.substring(1);
  }
  return `${baseUrl}/${address}`
}

export function absoluteUrl(relativeUrl: string): string {
  const location = window.location;
  const baseUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');

  // remove initial forward slash
  if (relativeUrl.startsWith('/')) {
    relativeUrl = relativeUrl.substring(1);
  }

  return `${baseUrl}/${relativeUrl}`;
}

export async function limeApiFetch(url: string, method: string, auth: AuthContextProps, body?: string | null) {
  return fetch(composeUrl(url),
    {
      headers: [[ 'Authorization',  `Bearer ${auth.user?.access_token}` ]],
      method: method,
      body: body
    });
}

export async function limeApiGet<T>(url: string, auth: AuthContextProps) {
  return limeApiFetch(url, 'GET', auth)
    .then(response => {
      if (response.ok)
        return response.json() as Promise<T>;
      throw response;
    });
}

export async function limeApiPost<T>(url: string, auth: AuthContextProps, request?: object | null) {
  const body = request ? JSON.stringify(request) : null;
  return limeApiFetch(url, 'POST', auth, body)
    .then(response => {
      if (response.ok)
        return response.json() as Promise<T>;
      throw response;
    });
}

export async function limeApiPut<T>(url: string, request: object, auth: AuthContextProps) {
  const body = request ? JSON.stringify(request) : null;
  return limeApiFetch(url, 'POST', auth, body)
    .then(response => {
      if (response.ok)
        return response.json() as Promise<T>;
      throw response;
    });
}

export async function limeApiDelete<T>(url: string, auth: AuthContextProps) {
  return limeApiFetch(url, 'DELETE', auth)
    .then(response => {
      if (response.ok)
        return response.json() as Promise<T>;
      throw response;
    });
}

export async function checkStatusBackoff<T>(
  checkStatus: () => Promise<boolean>)
  : Promise<boolean> {
  return checkStatusBackoffInternal(checkStatus, Date.now(), 1, 1);
}

async function checkStatusBackoffInternal<T>(
  checkStatus: () => Promise<boolean>,
  start: number,
  attempt: number,
  delay: number)
  : Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    let elapsed = Math.abs((Date.now() - start) / 1000);
    delay = Math.min((attempt * 3) - delay, 10);
    if (await checkStatus()) {
      resolve(true);
    } else if (delay + elapsed > 60) {
      resolve(false);
    } else {
      // Back-off delay over 60~ secs
      setTimeout(() =>
          checkStatusBackoffInternal(checkStatus, start, ++attempt, delay)
            .then(resolve).catch(reject),
        delay * 1000);
    }
  });
}