import React from "react";
import { useAuth } from "react-oidc-context";
import { hasRoleClaim } from "../../../utils/authUser";

interface Props {
  roles: string[],
  children: JSX.Element;
}

function RoleFilter({roles, children}: Props) {
  const auth = useAuth();

  let hasRole = false;
  roles.forEach(role => {
    if (hasRoleClaim(auth.user, role)) {
      hasRole = true;
    }
  });
  
  return auth?.user && hasRole ? children : <></>;
}

export default RoleFilter;