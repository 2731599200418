import React from 'react';

interface Props {
  title: string;
  icon: string;
  onClick: () => void;
}

function DropdownMenuItem({title, icon, onClick}: Props) {
  return (
    <div className="grid grid-cols-2 grid-rows-auto w-16 hover:cursor-pointer hover:underline" onClick={() => onClick() }>
      <img src={icon} alt={title} className="h-md mt-1"/>
      <span className="whitespace-nowrap">{title}</span>
    </div>
  )
}

export default DropdownMenuItem;