import React, {useState, useEffect} from 'react';
import {useAuth} from 'react-oidc-context';
import {useNavigate} from 'react-router-dom';
import {loadStripe, Stripe} from '@stripe/stripe-js';
import {absoluteUrl, limeApiGet} from '../../utils/api';
import {LimePaymentMethod, Payment, PaymentRetryInitialised, PaymentStatusId} from "./types";
import {LimeConfig} from "../../utils/limeConfig";
import {hasRoleClaim} from "../../utils/authUser";
import WellKnownRoles from "../../models/wellKnownRoles";

interface Props {
    payment: Payment | null;
    onPaymentProcessed: () => void;
}

const PaymentDirectDebit: React.FC<Props> = ({payment, onPaymentProcessed}) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [config, setConfig] = useState<LimeConfig>();
    const [stripe, setStripe] = useState<Stripe | null>(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

    useEffect(() => {
        const fetchConfigAndInitializeStripe = async () => {
            if (!hasRoleClaim(auth.user, WellKnownRoles.OrganisationBillingManager)) {
                navigate('/access-denied');
                return;
            }
            try {
                const config = await limeApiGet<LimeConfig>('configuration', auth);
                setConfig(config);

                if (config?.stripeApiKey) {
                    const stripeInstance = await loadStripe(config.stripeApiKey);
                    setStripe(stripeInstance);
                } else {
                    setError('Stripe publishable key is missing in configuration');
                }
            } catch (error) {
                setError('Failed to load configuration');
            }
        };

        fetchConfigAndInitializeStripe();
    }, [auth, navigate]);

    const fetchCheckoutSession = async () => {
        if (!stripe) return;
        setIsButtonDisabled(true);

        try {
            const response = await fetch(absoluteUrl('stripe/CreateCheckoutSession'), {
                headers: {
                    'Authorization': `Bearer ${auth.user?.access_token}`,
                },
                method: 'POST',
            });

            if (response.ok) {
                const data = await response.json();
                const result = await stripe.redirectToCheckout({
                    sessionId: data.sessionId,
                });

                if (result.error) {
                    setError(result.error.message ?? "There was an error creating the session");
                    setIsButtonDisabled(false);
                }
            } else {
                setError('Failed to create checkout session');
                setIsButtonDisabled(false);
            }
        } catch (error) {
            setError('An unexpected error occurred');
            setIsButtonDisabled(false);
        }
    };

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="bg-super-light-grey w-full py-lg px-xl rounded-md">
            <p>
                By clicking on the button, you will be redirected to Stripe to setup the direct debit.
            </p>
            <p>
                You will be redirected back to this website once setup is complete.
            </p>
            <div className="my-xl text-end">
                {stripe ? (
                    <button
                        className={'bg-button-background-blue border-button-background-blue hover:underline font-bold font-sans-co text-primary-text rounded-md mb-4'}
                        onClick={fetchCheckoutSession}
                        disabled={isButtonDisabled}>
                        {isButtonDisabled ? "Processing..." : "Setup Direct Debit"}
                    </button>
                ) : (
                    <div>Loading...</div>
                )}
            </div>
        </div>
    );
};

export default PaymentDirectDebit;