import React from "react";
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  Outlet,
  RouterProvider
} from "react-router-dom";
import { AuthProvider } from "react-oidc-context"
import App from "./components/App";
import LimeApi from "./models/limeApi";
import Members from "./components/Members";
import Dashboard from "./components/Dashboard";
import Billing from "./components/Billing";
import Users from "./components/Users";
import Review from "./components/Members/Review";
import Details from "./components/Members/Details";
import AccessDenied from "./components/AccessDenied";
import Error from "./components/Error";
import Profile from "./components/Profile";
import { composeUrl } from "./utils/api";
import Impersonate from "./components/Impersonate";
import PaymentMethod from "./components/Billing/PaymentMethod";
import PaymentMethodAdded from "./components/Billing/PaymentMethodAdded";
import RequireRole from "./components/Shared/RequireRole";
import WellKnownRoles from "./models/wellKnownRoles";
import {WebStorageStateStore} from "oidc-client-ts";

let load = async () => {
  try {
    const url = composeUrl('configuration')
    const response = await fetch(url);
    const config = await response.json();

    const oidcConfig = {
      authority: config.auth,
      client_id: "LimeOrganisationPortal",
      redirect_uri: config.redirectUri,
      response_type: "code",
      scope: `openid profile ${LimeApi.OrganisationGateway}`,
      loadUserInfo: true,
      onSigninCallback: (_user: any): void => {
        window.history.replaceState(
          {},
          document.title,
          _user.state
        )
      }
    }

    const router = createBrowserRouter([
      {
        errorElement: <Error/>,
        path: "/",
        element: <App/>,
        handle: {
          crumb: () => "Organisation Portal"
        },
        children: [
          {
            path: "dashboard",
            element: <Dashboard/>,
          },
          {
            path: "members",
            element: <RequireRole roles={[WellKnownRoles.OrganisationMemberManager]}><Members/></RequireRole>,
            handle: {
              crumb: () => "Members"
            }
          },
          {
            path: "members",
            element: <Outlet/>,
            children: [
              {
                path: "review/:memberFileId",
                element: <RequireRole roles={[WellKnownRoles.OrganisationMemberManager]}><Review/></RequireRole>
              }
            ]
          },
          {
            path: "members",
            element: <Outlet/>,
            children: [
              {
                path: "details/:memberFileId",
                element: <RequireRole roles={[WellKnownRoles.OrganisationMemberManager]}><Details/></RequireRole>
              }
            ]
          },
          {
            path: "billing",
            element: <RequireRole roles={[WellKnownRoles.OrganisationBillingViewer, WellKnownRoles.OrganisationBillingManager]}><Billing/></RequireRole>,
            handle: {
              crumb: () => "Billing"
            }
          },
          {
            path: "billing",
            element: <Outlet/>,
            handle: {
              crumb: () => "Billing"
            },
            children: [
              {
                path: "payment",
                element: <RequireRole roles={[WellKnownRoles.OrganisationBillingManager]}><PaymentMethod/></RequireRole>,
                handle: {
                  crumb: (state: any) => `${state?.updatePaymentMethod ? 'Change' : 'Add'} Payment Method`
                }
              },
              {
                path: "payment-method-added",
                element: <RequireRole roles={[WellKnownRoles.OrganisationBillingManager]}><PaymentMethodAdded/></RequireRole>,
                handle: {
                  crumb: () => "Payment Method Added"
                }
              }
            ]
          },
          {
            path: "users",
            element: <RequireRole roles={[WellKnownRoles.OrganisationUserAdmin]}><Users/></RequireRole>,
            handle: {
              crumb: () => "Users"
            }
          },
          {
            path: "profile",
            element: <Profile/>,
            handle: {
              crumb: () => "Profile"
            }
          },
          {
            path: "impersonate/:organisationId",
            element: <Impersonate/>
          }
        ],
      },
      {
        path: "access-denied",
        element: <AccessDenied/>
      },
      {
        path: "error",
        element: <Error/>
      }
    ]);

    const root = ReactDOM.createRoot(
      document.getElementById('root') as HTMLElement
    );
    root.render(
      <React.StrictMode>
        <AuthProvider {...oidcConfig}>
          <RouterProvider router={router}/>
        </AuthProvider>
      </React.StrictMode>,
    );
  } catch (e) {
    console.error(e);
  }
}

void load();