function getDateOrdinal(d: number): string {
    return d+(31==d||21==d||1==d?"st":22==d||2==d?"nd":23==d||3==d?"rd":"th")
}

export function formatViewDateAndTimeLong(date: undefined | Date): string | undefined {
    if (!date) {
        return '';
    }

    date = new Date(date);
    let day = date.toLocaleString('en-GB', { weekday: 'long' });
    return `${day} ${formatViewDate(date)} ${formatViewTime(date)}`;
}

export function formatViewDateAndTimeShort(date: undefined | Date): string | undefined {
    if (!date) {
        return '';
    }

    date = new Date(date);
    return `${formatViewDate(date)} ${formatViewTime(date)}`;
}

export function formatViewDate(date: undefined | Date): string | undefined {
  if (!date) {
    return '';
  }

  date = new Date(date);
  return `${padValue(date.getDate())}/${padValue(date.getMonth() + 1)}/${date.getFullYear().toString().substr(-2)}`;
}

export function formatViewDateLong(date: undefined | Date): string | undefined {
  if (!date) {
    return '';
  }

  date = new Date(date);
  return `${date.getDate()} ${getShortMonthName(date)} ${date.getFullYear()}`;
}

export function formatViewDateMonthAndYearShort(date: undefined | Date): string {
  if (!date) {
    return '';
  }

  date = new Date(date);
  return `${padValue(date.getMonth())}/${date.getFullYear()}`;
}

export function formatViewDateWithoutYear(date: undefined | Date): string | undefined {
    if (!date) {
        return '';
    }

    date = new Date(date);
    let day = date.toLocaleString('en-GB', { weekday: 'short' });
    let dateOrdinal = getDateOrdinal(date.getDate());

    return `${day} ${dateOrdinal} ${getShortMonthName(date)}`;
}

export function formatViewTime(date: undefined | Date): string | undefined {
  if (!date) {
    return '';
  }

  date = new Date(date);

  let amPm = "AM";
  let hours = date.getHours();

  if (hours >= 12) {
      amPm = "PM";

      if (hours > 12) {
          hours = hours - 12;
      }
  }

  return `${hours}:${padValue(date.getMinutes())} ${amPm}`;
}

export function getShortMonthName(date: Date): string {
    if (!date) {
        return '';
    }

    return date.toLocaleString('en-US', { month: 'short' });
}

function padValue(value: number): string {
    return value < 10 ? "0" + value.toString() : value.toString();
}