import React, { useState } from 'react';
import Checked from "../../images/checked.svg";
import Unchecked from "../../images/unchecked.svg";
import ChevronDown from "../../images/chevron-down.svg";
import ChevronUp from "../../images/chevron-up.svg";
import { OnboardingTask } from "./types";

interface Props {
  task: OnboardingTask
}

function OnboardingTaskItem({ task }: Props) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
      <li className="my-4">
        <div className="flex justify-start">
          { task.isComplete
            ? <img className="w-7 h-7 mt-1.5" src={Checked} alt="checked" />
            : <img className="w-7 h-7 mt-1.5" src={Unchecked} alt="unchecked" />
          }
          <h4 className="text-p1 lg:text-h4 lg:font-normal mx-3 mt-1">{task.title}</h4>
          { task.description && <img src={isExpanded ? ChevronDown : ChevronUp} alt="" className="hover:cursor-pointer" onClick={() => setIsExpanded(!isExpanded) } /> }
          { task.url && !task.isComplete && <div className="ml-auto">
              <a className="justify-end w-fit block px-lg py-sm font-bold font-sans-co text-primary-text visited:text-primary-text active:text-primary-text bg-button-background-blue rounded-md" href={task.url}>Continue</a>
            </div>
          }
        </div>
        { isExpanded && <p className="ml-9 mt-2">{task.description}</p> }
      </li>
    );
}

export default OnboardingTaskItem;
