import React, { useState } from 'react';
import { useAuth } from "react-oidc-context";
import { useLocation } from "react-router-dom";
import { User } from "oidc-client-ts";
import { claimValueContains, getClaimValue } from "../../utils/authUser";
import WellKnownRoles from "../../models/wellKnownRoles";
import NavBarAction from "./NavBarAction";
import NavBarItem from "./NavBarItem";
import LogoHeart from "../../images/lime-heart.svg";
import LogoDark from "../../images/lime-dark.svg";
import NavHam from "../../images/nav-ham.svg";
import NavClose from "../../images/x-close.svg";
import DashboardDark from "../../images/dashboard-dark.svg";
import DashboardLight from "../../images/dashboard-light.svg";
import BillingDark from "../../images/billing-dark.svg";
import BillingLight from "../../images/billing-light.svg";
import MembersDark from "../../images/members-dark.svg";
import MembersLight from "../../images/members-light.svg";
import UsersDark from "../../images/users-dark.svg";
import UsersLight from "../../images/users-light.svg";
import ProfileDark from "../../images/profile-dark.svg";
import ProfileLight from "../../images/profile-light.svg";
import Logout from "../../images/logout.svg";
import CheckHeart from "../../images/check-heart.svg";
import LimeClaimTypes from "../../models/limeClaimTypes";
import UserType from "../../models/userType";
import OrganisationUserType from "../../models/organisationUserType";
import { composeUrl } from "../../utils/api";
import RoleFilter from "../Shared/RoleFilter";

function NavBar() {
  const auth = useAuth()
  const location = useLocation();
  const [canSwitchSpace, setCanSwitchSpace] = useState<boolean>(false);
  const [memberPortalUrl, setMemberPortalUrl] = useState("#");
  const [displayNav, setDisplayNav] = useState(false);

  const currentNav = location.pathname.substring(1).split('/')[0].toLowerCase();

  React.useEffect(() => setDisplayNav(false), [location]);

  React.useEffect(() => {
    let isOrganisationUser = (user: User | null | undefined) => getClaimValue(user, LimeClaimTypes.UserType) === UserType.Organisation && getClaimValue(user, LimeClaimTypes.OrganisationUuid);
    let isOrganisationAdmin = (user: User | null | undefined) => claimValueContains(user, LimeClaimTypes.OrganisationUserType, OrganisationUserType.Admin);
    let isOrganisationMember = (user: User | null | undefined) => claimValueContains(user, LimeClaimTypes.OrganisationUserType, OrganisationUserType.Member);

    if (isOrganisationUser(auth.user) && isOrganisationAdmin(auth.user) && isOrganisationMember(auth.user)) {
      setCanSwitchSpace(true);
    }
  }, [auth, auth.user]);

  React.useEffect(() => {
    const fetchData = async () => {
      const configUrl = composeUrl('configuration');
      const response = await fetch(configUrl);
      const config = await response.json();
      setMemberPortalUrl(config.member);
    }
    void fetchData();
  }, []);

  return (
    <div className="flex justify-between items-center sm:flex-col sm:items-start sm:p-lg sm:h-full">
      <img src={LogoHeart} alt="lime logo" className="h-[2rem] sm:hidden"/>
      <img src={LogoDark} alt="lime logo" className="hidden sm:block sm:flex-shrink sm:w-full sm:mb-lg"/>
      <button className="px-sm p-xs w-fit rounded-lg sm:hidden font-sans-co">
        {currentNav && currentNav.length && currentNav[0].toUpperCase() + currentNav.substring(1)}
      </button>
      <input type="checkbox" id="nav-state" className="hidden peer" checked={displayNav}
             onChange={e => setDisplayNav(e.target.checked)}/>
      <label
        className="hidden sm:hidden bg-dark-grey/[.5] fixed top-0 bottom-0 left-0 w-full h-full sm:w-0 sm:h-0 z-30 peer-checked:block"
        htmlFor="nav-state"></label>
      <label htmlFor="nav-state" className="cursor-pointer sm:hidden">
        <img src={NavHam} alt="navigation menu"/>
      </label>

      <div className="hidden fixed bg-pure-white z-40 top-0 left-0 h-full p-lg peer-checked:block
        sm:relative sm:block sm:flex sm:flex-col sm:p-0 sm:h-full">
        <label htmlFor="nav-state" className="cursor-pointer sm:hidden inline-block mb-sm">
          <img src={NavClose} alt="close navigation" className="h-[2rem]"/>
        </label>

        <ul className="mb-2xl sm:mb-0 sm:flex-grow">
        <NavBarItem route={"dashboard"} title="Dashboard" icon={DashboardDark} activeIcon={DashboardLight}/>
        <RoleFilter roles={[WellKnownRoles.OrganisationMemberManager]}>
          <NavBarItem route={"members"} title="Members" icon={MembersDark} activeIcon={MembersLight}/>
        </RoleFilter>
        <RoleFilter roles={[WellKnownRoles.OrganisationBillingViewer, WellKnownRoles.OrganisationBillingManager]}>
          <NavBarItem route={"billing"} title="Billing" icon={BillingDark} activeIcon={BillingLight}/>
        </RoleFilter>
        <RoleFilter roles={[WellKnownRoles.OrganisationUserAdmin]}>
          <NavBarItem route={"users"} title="Users" icon={UsersDark} activeIcon={UsersLight}/>
        </RoleFilter>
      </ul>

        <ul className="sm:flex-shrink">
          {
            canSwitchSpace &&
            <li>
              <a href={memberPortalUrl} className="flex w-fit sm:w-full block px-sm py-xs sm:py-sm mb-md font-bold font-sans-co text-primary-text
                                     visited:text-primary-text active:text-primary-text bg-pure-white hover:no-underline hover:bg-super-light-grey
                                     rounded-md sm:rounded-lg border-solid border-4 border-lime-green">
                <img src={CheckHeart} alt="Logout" className="hidden sm:block sm:mr-md sm:h-md sm:mt-1"/> Switch Space
              </a>
            </li>
          }
          <NavBarItem route={"profile"} title="Profile" icon={ProfileDark} activeIcon={ProfileLight}/>
          <NavBarAction onClick={auth.signoutRedirect}>
            <img src={Logout} alt="Logout" className="hidden sm:block sm:mr-md sm:h-md sm:mt-1"/> Logout
          </NavBarAction>
        </ul>
      </div>
    </div>
  );
}

export default NavBar;