import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { User, UserManager } from "oidc-client-ts";
import { composeUrl } from "../../utils/api";
import { parseAccessToken } from "../../utils/token";

interface ImpersonationResponse {
  access_token: string,
  expires_in: number,
  issued_token_type: string,
  scope: string,
  token_type: string
}

function Impersonate() {
  const auth = useAuth();
  const params = useParams();
  let organisationId = params["organisationId"];
  const [error, setError] = useState<string>();

  React.useEffect(() => {
    (async () => {
      if (!auth.isAuthenticated) {
        return;
      }
      const configUrl = composeUrl('configuration');
      const fetchConfigResponse = await fetch(configUrl);
      const config = await fetchConfigResponse.json();

      const data: any = {
        'grant_type': 'urn:ietf:params:oauth:grant-type:token-exchange',
        'client_id': 'LimeOrganisationPortal',
        'subject_token': auth.user?.access_token,
        'subject_token_type': 'urn:ietf:params:oauth:token-type:access_token',
        'organisation_id': organisationId
      };

      let body = [];
      for (const key in data) {
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(data[key]);
        body.push(`${encodedKey}=${encodedValue}`);
      }

      const response = await fetch(`${config.auth}/connect/token`,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        method: 'POST',
        body: body.join("&")
      });

      if (response.ok) {
        const userStoreKey = `oidc.user:${config.auth}:LimeOrganisationPortal`;
        const responseBody : ImpersonationResponse = await response.json();
        let currentUserStoreValue = sessionStorage.getItem(userStoreKey);
        if (currentUserStoreValue) {
          const accessTokenJSON = parseAccessToken(responseBody.access_token);
          const storeUser = JSON.parse(currentUserStoreValue)
          storeUser.access_token = responseBody.access_token;
          storeUser.profile.lime_usertype = accessTokenJSON.lime_usertype;
          storeUser.profile.lime_organisation_uuid = accessTokenJSON.lime_organisation_uuid;
          storeUser.profile.lime_organisation_name = accessTokenJSON.lime_organisation_name;
          storeUser.profile.lime_organisation_user_type = accessTokenJSON.lime_organisation_user_type
          storeUser.profile.role = accessTokenJSON.role;

          sessionStorage.setItem(userStoreKey, JSON.stringify(storeUser));
          let newUserStoreValue = sessionStorage.getItem(userStoreKey);
          if (newUserStoreValue) {
            const newUser = User.fromStorageString(newUserStoreValue);
            const userManager = new UserManager(auth.settings);
            await userManager.storeUser(newUser);
            await userManager.stopSilentRenew(); // we dont want to renew the token for impersonated users
            await auth.clearStaleState();
          }
        }

        window.location.href = "/dashboard";
      } else {
        setError("Sorry an error has occurred");
      }
    })();
  }, [auth, auth.user]);

  return (
    <div>
      <h2>Impersonating organisation {organisationId}...</h2>
      <p className={`mb-md text-error-red${error ? '' : ' hidden'}`}>
        {error}
      </p>
    </div>
  );
}

export default Impersonate;