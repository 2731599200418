import React from 'react';

interface Props {
    name: string,
    tabKey: string,
    disabled?: boolean,
    tabSelected?(tabKey: string): void,
    selectedTabKey?: string;
}

function Tab(props: Props) {
    const handleClick = () => {
        if (props.tabSelected && !props.disabled) {
            props.tabSelected(props.tabKey);
        }
    }

    const computeClassName = () => {
        let result = `tab mx-xl my-md px-sm text-h4`;
        result += !props.disabled ? " hover:cursor-pointer" : " text-light-grey font-normal";
        if (props.selectedTabKey === props.tabKey) {
            result += " border-b-[5px] border-primary-text font-bold";
        } else {
            result += " font-normal";
        }
        return result;
    }

    return (
        <div className={computeClassName()} id={`tab-${props.tabKey}`} onClick={handleClick}>
            {props.name}
        </div>
    )
}

export default Tab;