import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {getClaimValue} from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import {composeUrl} from "../../utils/api";
import {MemberFileDetails} from "./Review";
import {useAuth} from "react-oidc-context";
import {MemberFileStatusId} from "./Members";
import {formatViewDateAndTimeShort} from "../../utils/dateFormat";
import LoadingSpinner from "../Shared/LoadingSpinner/LoadingSpinner";
import { saveAs } from 'file-saver';
import downloadIcon from "../../images/download.svg";
import checkCircle from "../../images/check-circle.svg";
import xCircle from "../../images/x-circle.svg";
import alertCircle from "../../images/alert-circle.svg";
import refreshIcon from "../../images/refresh.svg";
import MemberFileChanges from "./MemberFileChanges";
import useInterval from "../Shared/Hooks/useInterval";

function Details() {
    const auth = useAuth();
    const params = useParams();
    const navigate = useNavigate();

    const [memberFileDetails, setMemberFileDetails] = useState<MemberFileDetails>();
    const [loading, setLoading] = useState<boolean>(true);
    const [intervalDelay] = useState(5000);
    const [shouldAutoRefresh, setShouldAutoRefresh] = useState(false);

    useInterval(() => refresh, shouldAutoRefresh ? intervalDelay : null);

    const reviewedStatuses: MemberFileStatusId[] = [MemberFileStatusId.Abandoned, MemberFileStatusId.Confirmed];

    const navigateToMembers = () => {
        navigate("/members");
    }

    const navigateToReview = () => {
        navigate(`/members/review/${memberFileDetails?.id}`);
    }

    function refresh() {
        let memberFileId = params["memberFileId"];
        let orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
        fetch(composeUrl(`organisations/${orgUuid}/memberFiles/${memberFileId}`),
          {
              headers: [[ 'Authorization',  `Bearer ${auth.user?.access_token}` ]],
              method: 'GET'
          })
          .then(response => response.json() as Promise<MemberFileDetails>)
          .then(data => {
              setMemberFileDetails(data);
              setLoading(false);
              setShouldAutoRefresh(
                data.statusId === MemberFileStatusId.New ||
                data.statusId === MemberFileStatusId.Processing ||
                data.statusId === MemberFileStatusId.Confirming);
          });
    }

    React.useEffect(() => refresh(), [params]);

    const getStatusIcon = (statusId: MemberFileStatusId) => {
        switch (statusId) {
            case MemberFileStatusId.New:
            case MemberFileStatusId.Processing:
            case MemberFileStatusId.Confirming:
                return <img src={refreshIcon} alt="Confirmed" className="h-lg mr-md animate-spin"/>
            case MemberFileStatusId.Confirmed:
                return <img src={checkCircle} alt="Confirmed" className="h-lg mr-md"/>;
            case MemberFileStatusId.Abandoned:
                return <img src={xCircle} alt="Abandoned" className="h-lg mr-md"/>
            case MemberFileStatusId.Error:
                return <img src={alertCircle} alt="Error" className="h-lg mr-md"/>
            default:
                break;
        }
    }

    const downloadMemberFile = (filename: string) => {
        let memberFileId = params["memberFileId"];
        let orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
        fetch(composeUrl(`organisations/${orgUuid}/memberFiles/${memberFileId}/content`),
            {
                headers: [[ 'Authorization',  `Bearer ${auth.user?.access_token}` ]],
                method: 'GET'
            })
            .then(response => response.blob())
            .then(blob => {
                saveAs(blob, filename);
            });
    }

    return (
        <div>
            <div className="items-start mb-xl">
                <h1 className="mb-xl">Members</h1>
                <h3 className="mb-md">File Details</h3>
                <LoadingSpinner loading={loading}>
                    { memberFileDetails &&
                        <div className="w-fit rounded-md bg-super-light-grey p-lg mb-xl">
                            <table>
                                <tbody>
                                    <tr className="h-2xl">
                                        <td className="w-[8rem] text-p1"><b>Name</b></td>
                                        <td>
                                            <div className="grow flex flex-row items-center">
                                                <div className="mr-xl">
                                                    {memberFileDetails.filename}
                                                </div>
                                                <button
                                                    className="w-fit h-fit py-xs px-md font-sans-co"
                                                    onClick={() => downloadMemberFile(memberFileDetails?.filename)}>
                                                    <div className="flex flex-row items-center justify-between font-normal">
                                                        <img src={downloadIcon} alt="Download_Icon" className="mr-sm h-md" />
                                                        <div className="ml-sm">
                                                            Download
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="h-2xl text-p1">
                                        <td className="w-[8rem]"><b>Uploaded</b></td>
                                        <td>{`${formatViewDateAndTimeShort(memberFileDetails.uploadedOn)} by ${memberFileDetails.uploadedByUser}`}</td>
                                    </tr>
                                    {
                                        reviewedStatuses.includes(memberFileDetails.statusId) &&
                                        <tr className="h-2xl text-p1">
                                            <td className="w-[8rem]"><b>Reviewed</b></td>
                                            <td>{`${formatViewDateAndTimeShort(memberFileDetails.reviewedOn)} by ${memberFileDetails.reviewedByUser}`}</td>
                                        </tr>
                                    }
                                    <tr className="h-2xl">
                                        <td className="w-[8rem] text-p1"><b>Status</b></td>
                                        <td>
                                            <div className="grow flex flex-row items-center">
                                                {getStatusIcon(memberFileDetails.statusId)}
                                                {MemberFileStatusId[memberFileDetails.statusId]}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                    {
                        memberFileDetails &&
                        reviewedStatuses.includes(memberFileDetails.statusId) &&
                        <div className="mb-md">
                            <h3 className="mb-lg">{`${MemberFileStatusId[memberFileDetails.statusId]} Changes`}</h3>
                            <MemberFileChanges memberFileDetails={memberFileDetails}/>
                        </div>
                    }
                    {
                        memberFileDetails &&
                        memberFileDetails.statusId === MemberFileStatusId.Error &&
                        <div className="mb-md">
                            <h3 className="mb-md">File Errors ({memberFileDetails.errors.length})</h3>
                            <table className="w-full">
                                <tbody>
                                {
                                    memberFileDetails.errors.map((err, i) =>
                                        <tr className="text-left bg-pure-white even:bg-super-light-grey h-lg" key={i}>
                                            <td className="p-sm border-b-4 border-background-blu rounded-md">
                                                {err.message}
                                            </td>
                                        </tr>)
                                }
                                </tbody>
                            </table>
                        </div>
                    }
                    <button
                        className="bg-pure-white border-solid border border-primary-text rounded-full text-primary-text font-sans-co mr-md"
                        onClick={navigateToMembers}>
                        Go Back
                    </button>
                    {
                        memberFileDetails &&
                        memberFileDetails.statusId === MemberFileStatusId.Review &&
                        <button className="font-sans-co"
                            onClick={navigateToReview}>
                            Review Changes
                        </button>
                    }
                </LoadingSpinner>
            </div>
        </div>
    );
}

export default Details;