import React, { useState } from 'react';
import { useAuth } from "react-oidc-context";
import { getClaimValue } from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import { composeUrl } from "../../utils/api";
import OkCancelModal from "../Shared/Modal/OkCancelModal";
import { useForm } from "react-hook-form";
import { User } from "./types";

interface Props {
  user?: User,
  isOpen: boolean,
  setIsOpen: (value: boolean) => void,
  onChangeEmail: () => void
}

type FormData = {
  email: string;
};

function ChangeUserEmail({user, isOpen, setIsOpen, onChangeEmail}: Props) {
  const auth = useAuth();
  const [error, setError] = useState<string>();
  const {register, reset, handleSubmit, getValues, formState: {errors}} = useForm<FormData>({mode: "onBlur", reValidateMode: "onBlur"});

  const onSubmit = async (data: FormData) => {
    try {
      let orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
      const response = await fetch(composeUrl(`organisations/${orgUuid}/administrators/${user?.id}/email`),
      {
        headers: [[ 'Authorization',  `Bearer ${auth.user?.access_token}` ], [ 'Content-Type', 'application/json' ]],
        method: 'PUT',
        body: JSON.stringify(data)
      });

      if (response.ok) {
        close()
        onChangeEmail();
      } else {
        const responseBody = await response.json();
        if (responseBody.hasOwnProperty('code') && responseBody.hasOwnProperty('message')) {
          setError(responseBody.message);
        } else {
          setError("Sorry an error has occurred");
        }
      }
    }
    catch (e) {
      setError("Sorry an error has occurred");
    }
  }

  function close() {
    reset();
    setError("");
    setIsOpen(false);
  }

  function validateCurrentEmail() {
    const data = getValues();
    return data.email !== user?.email;
  }

  return (
    <OkCancelModal
      title="Change Email"
      okLabel="Change Email"
      okClick={handleSubmit(onSubmit)}
      cancelLabel="Cancel"
      cancelClick={close}
      show={isOpen}
      errorMessage={error}>
      <div className="mb-xl w-96 text-left mb-2 mx-auto">
        <form>
          <div className="mb-2">
            <span className="text-p1 font-bold mb-2 mr-8">User</span>
            <span>{`${user?.firstName} ${user?.lastName}`}</span>
          </div>
          <div className="mb-2">
            <span className="text-p1 font-bold mb-2 mr-8">Current Email</span>
            <span>{user?.email}</span>
          </div>
          <div className="mb-4">
            <label className="block text-p1 font-bold">New Email</label>
            <input className="border border-solid rounded-md w-full pl-2" type="text" {...register("email", {required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, validate: validateCurrentEmail})} />
            {errors.email && <p className="mt-1 text-p2 text-error-red">Valid email address required</p>}
          </div>
        </form>
      </div>
    </OkCancelModal>
  );
}

export default ChangeUserEmail;