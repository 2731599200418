export enum PaymentMethodStatus {
  Loading,
  Required,
  Active,
  Error
}

export enum PaymentTypeId {
  Charge = 1,
  Refund
}

export enum PaymentStatusId {
  Successful = 3,
  Failed = 4
}

export enum PaymentFailureReasonId {
  Unknown = 1,
  GenericDecline,
  AuthenticationRequired,
  ExpiredCard,
  InsufficientFunds,
  InvalidPostcode,
  UnsupportedCard
}

export interface Invoice {
  id: number,
  ref: string,
  date: Date,
  total: number,
  statusName: string,
  products: InvoiceProduct[]
}

export interface InvoiceProduct {
  id: number,
  name: string
}

export interface Payment {
  id: number,
  paymentProviderId: string,
  date: Date,
  amount: number,
  typeId: PaymentTypeId,
  typeName: string,
  statusId: PaymentStatusId,
  statusName: string,
  paymentMethodId: string,
  paymentMethodPaymentProviderId: string,
  paymentMethodBrand: string,
  paymentMethodLast4Digits: string,
  failureReasonId?: PaymentFailureReasonId,
  failureReasonName: string,
  displayFailureReason: string,
  invoices: LinkedInvoice[]
}

export interface PaymentRetryInitialised {
  id: number,
  stripeClientSecret: string
}

export interface LinkedInvoice {
  id: number,
  ref: string
}

export interface LimePaymentMethod {
  id: number,
  orgUuid: number,
  summary: string,
  brand: string,
  last4Digits: string,
  expiryMonth: number,
  expiryYear: number,
  postcode: string,
  paymentProviderId: string
}