import React, {useState} from 'react';
import {useAuth} from "react-oidc-context";
import {getClaimValue} from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import {composeUrl} from "../../utils/api";
import OkCancelModal from "../Shared/Modal/OkCancelModal";
import {useForm} from "react-hook-form";

interface Props {
    isOpen: boolean,
    setIsOpen: (value: boolean) => void,
    onAdd: () => void
}

type FormData = {
    firstName: string;
    lastName: string;
    email: string;
    memberManager: boolean;
    billingManager: boolean;
    userAdministrator: boolean;
};

function AddUser({isOpen, setIsOpen, onAdd}: Props) {
    const auth = useAuth();
    const [error, setError] = useState<string>();
    const {register, reset, handleSubmit, getValues, formState: {isDirty, errors}} = useForm<FormData>({
        mode: "onBlur",
        reValidateMode: "onBlur"
    });
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data: FormData) => {
        setLoading(true);

        try {
            if (isDirty) {
                let request = {
                    firstName: data.firstName.trim(),
                    lastName: data.lastName.trim(),
                    email: data.email.trim(),
                    roles: new Array<string>()
                };
                if (data.memberManager) {
                    request.roles.push("Organisation Member Manager");
                }
                if (data.billingManager) {
                    request.roles.push("Organisation Billing Manager");
                }
                if (data.userAdministrator) {
                    request.roles.push("Organisation User Administrator");
                }
                let orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
                const response = await fetch(composeUrl(`organisations/${orgUuid}/administrators/`),
                    {
                        headers: [['Authorization', `Bearer ${auth.user?.access_token}`], ['Content-Type', 'application/json']],
                        method: 'POST',
                        body: JSON.stringify(request)
                    });

                if (response.ok) {
                    cancel();
                    onAdd();
                } else {
                    const responseBody = await response.json();
                    if (responseBody.hasOwnProperty('code') && responseBody.hasOwnProperty('message')) {
                        setError(responseBody.message);
                    } else {
                        setError("Sorry an error has occurred");
                    }
                    setLoading(false);
                }
            } else {
                onAdd();
            }
        } catch (error) {
            console.error(error);
            setError("Sorry an error has occurred");
        } finally {
            setLoading(false);
        }
    }

    function cancel() {
        reset();
        setError("")
        setIsOpen(false);
    }

    function validateRoles() {
        const data = getValues();
        return data.memberManager || data.billingManager || data.userAdministrator;
    }

    function validateWhitespaceForRequired(value: string): boolean {
        return !!value.trim();
    }

    return (
        <OkCancelModal
            title="Add User"
            okLabel="Add"
            okClick={loading ? () => {
            } : handleSubmit(onSubmit)}
            cancelLabel="Cancel"
            cancelClick={cancel}
            show={isOpen}
            errorMessage={error}
            isLoading={loading}>
            <div className="mb-xl w-96 text-left mb-2 mx-auto">
                <form>
                    <div className="mb-2">
                        <label className="block text-p1 font-bold mb-2">First Name</label>
                        <input
                            className={`border border-solid rounded-md w-full pl-2 ${errors.firstName ? 'border-error-red border-2' : ''}`}
                            type="text" {...register("firstName", {
                            required: true,
                            maxLength: 80,
                            validate: validateWhitespaceForRequired
                        })} />
                        {errors.firstName && <p className="mt-1 text-p2 text-error-red">
                            {errors.firstName.type === "required" && "Value required"}
                            {errors.firstName.type === "validate" && "Value required"}
                            {errors.firstName.type === "maxLength" && "Max length exceeded"}
                        </p>}
                    </div>
                    <div className="mb-2">
                        <label className="block text-p1 font-bold">Last Name</label>
                        <input className="border border-solid rounded-md w-full pl-2"
                               type="text" {...register("lastName", {
                            required: true,
                            maxLength: 100,
                            validate: validateWhitespaceForRequired
                        })} />
                        {errors.lastName && <p className="mt-1 text-p2 text-error-red">
                            {errors.lastName.type === "required" && "Value required"}
                            {errors.lastName.type === "validate" && "Value required"}
                            {errors.lastName.type === "maxLength" && "Max length exceeded"}
                        </p>}
                    </div>
                    <div className="mb-4">
                        <label className="block text-p1 font-bold">Email</label>
                        <input className="border border-solid rounded-md w-full pl-2"
                               type="text" {...register("email", {
                            required: true,
                            validate: validateWhitespaceForRequired,
                            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        })} />
                        {errors.email && <p className="mt-1 text-p2 text-error-red">Valid email address required</p>}
                    </div>
                    <h4 className="mb-2">Roles</h4>
                    <div>
                        <input type="checkbox" {...register("memberManager", {
                            required: false,
                            validate: validateRoles
                        })} />
                        <span className="ml-4 text-p1 font-bold">Member Manager</span>
                    </div>
                    <div>
                        <input type="checkbox" {...register("billingManager", {
                            required: false,
                            validate: validateRoles
                        })} />
                        <span className="ml-4 text-p1 font-bold">Billing Manager</span>
                    </div>
                    <div>
                        <input type="checkbox" {...register("userAdministrator", {
                            required: false,
                            validate: validateRoles
                        })} />
                        <span className="ml-4 text-p1 font-bold">User Administrator</span>
                    </div>
                    {(errors.memberManager || errors.billingManager || errors.userAdministrator) &&
                        <p className="mt-1 text-p2 text-error-red">At least one role must be selected</p>}
                </form>
            </div>
        </OkCancelModal>
    );
}

export default AddUser;