import React, { useState } from 'react';
import { useAuth } from "react-oidc-context";
import { getClaimValue } from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import { composeUrl } from "../../utils/api";
import OkCancelModal from "../Shared/Modal/OkCancelModal";
import { User } from "./types";

interface Props {
  user?: User,
  isOpen: boolean,
  setIsOpen: (value: boolean) => void,
  onChangeAccess: () => void
}

function ChangeUserAccess({user, isOpen, setIsOpen, onChangeAccess}: Props) {
  const auth = useAuth();
  const [error, setError] = useState<string>();

  const changeAccess = async () => {
    try {
      let orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
      const response = await fetch(composeUrl(`organisations/${orgUuid}/administrators/${user?.id}/${user?.accessDisabled ? "enable" : "disable"}`),
      {
        headers: [[ 'Authorization',  `Bearer ${auth.user?.access_token}` ]],
        method: 'POST',
      });

      if (response.ok) {
        close()
        onChangeAccess();
      } else {
        const responseBody = await response.json();
        if (responseBody.hasOwnProperty('code') && responseBody.hasOwnProperty('message')) {
          setError(responseBody.message);
        } else {
          const responseBody = await response.json();
          if (responseBody.hasOwnProperty('code') && responseBody.hasOwnProperty('message')) {
            setError(responseBody.message);
          } else {
            setError("Sorry an error has occurred");
          }
        }
      }
    }
    catch (e) {
      setError("Sorry an error has occurred");
    }
  }

  function close() {
    setError("");
    setIsOpen(false);
  }

  return (
    <OkCancelModal
      title={`${user?.accessDisabled ? "Enable" : "Disable"} User`}
      okLabel={`${user?.accessDisabled ? "Enable" : "Disable"} Access`}
      okClick={changeAccess}
      cancelLabel="Cancel"
      cancelClick={close}
      show={isOpen}
      errorMessage={error}>
      <div className="mb-xl">
        <p>Are you sure you want to {user?.accessDisabled ? "re-enable" : "disable"} <b>{`${user?.firstName} ${user?.lastName}`}'s</b> access to the organisation portal?</p>
      </div>
    </OkCancelModal>
  );
}

export default ChangeUserAccess;