import React, {useRef, useState} from 'react';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    ElementsConsumer,
    useElements,
} from '@stripe/react-stripe-js';
import {useForm} from "react-hook-form";
import StateButton, {ButtonStyle} from "../Shared/StateButton/StateButton";
import {useAuth} from "react-oidc-context";
import {confirmPaymentWithNewCard, confirmNewCardSetup, IStripeCardDetails} from "./StripePaymentService";
import {useNavigate} from "react-router-dom";
import {getClaimValue, hasRoleClaim} from "../../utils/authUser";
import WellKnownRoles from "../../models/wellKnownRoles";
import {absoluteUrl, checkStatusBackoff, limeApiFetch, limeApiGet, limeApiPost} from "../../utils/api";
import {LimeConfig} from "../../utils/limeConfig";
import {Stripe} from "@stripe/stripe-js";
import {LimePaymentMethod, Payment, PaymentRetryInitialised, PaymentStatusId} from "./types";
import LimeClaimTypes from "../../models/limeClaimTypes";

interface SetupIntentResponse {
    id: string,
    clientSecret: string
}

interface Props {
    stripe: Stripe,
    payment: Payment | null,
    onPaymentProcessed: () => void
}

function PaymentCard({stripe, payment, onPaymentProcessed}: Props) {
    const auth = useAuth();
    const navigate = useNavigate();
    const [name, setName] = useState<string>();
    const [postcode, setPostcode] = useState<string>();
    const [config, setConfig] = useState<LimeConfig>();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const methods = useForm<IStripeCardDetails>({mode: "onChange", reValidateMode: "onChange"});
    const elements = useElements();
    const paymentRef = useRef(payment);

    React.useEffect(() => {
        (async () => {
            if (!hasRoleClaim(auth.user, WellKnownRoles.OrganisationBillingManager)) {
                navigate('/access-denied');
            }
            const config = await limeApiGet<LimeConfig>('configuration', auth);
            setConfig(config);
        })();
    }, []);

    const navigateToBilling = () => {
        navigate('/billing');
    }

    const setUnexpectedError = () =>{
        setError('Sorry an expected error occurred');
    }

    const outstandingBalance = () => payment && payment.statusId == PaymentStatusId.Failed;

    const saveOnly = async (cardDetails: IStripeCardDetails) => {
        const setupResponse = await fetch(absoluteUrl('stripe/setupIntent'), {
            headers: [['Authorization', `Bearer ${auth.user?.access_token}`]],
            method: 'POST'
        });
        if (setupResponse.ok && cardDetails.card && config) {
            const data: SetupIntentResponse = await setupResponse.json();
            const stripeResponse = await confirmNewCardSetup(stripe, data.clientSecret, cardDetails, config);
            if (stripeResponse.error) {
                setError(stripeResponse.error.message);
                return false;
            }
            // Poll for new payment method
            let orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
            const result = await checkStatusBackoff(
                async () => {
                    let response = await limeApiFetch(`organisations/${orgUuid}/paymentMethods`, 'GET', auth);
                    if (!response.ok) {
                        return false;
                    }
                    let limePaymentMethod: LimePaymentMethod = await response.json();
                    return limePaymentMethod.paymentProviderId == stripeResponse.setupIntent.payment_method;
                });

            if (result) {
                return true;
            }
        }

        setUnexpectedError();
        return false;
    }

    const saveAndPay = async (cardDetails: IStripeCardDetails) => {
        if (!config || !payment) {
            setUnexpectedError();
            return false;
        }
        let orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
        const retryResponse = await limeApiPost<PaymentRetryInitialised>(
            `organisations/${orgUuid}/payments/${payment.id}/retry`,
            auth);
        const stripeResponse = await confirmPaymentWithNewCard(stripe, retryResponse.stripeClientSecret, cardDetails, config);
        if (stripeResponse.error) {
            setError(stripeResponse.error.message);
            if (!stripeResponse.error.charge || stripeResponse.error.charge == payment.paymentProviderId) {
                // No new payment created
                return false;
            }
        }
        // poll for new payment
        let result = await checkStatusBackoff(
            async () => {
                let payments = await limeApiGet<Payment[]>(`organisations/${orgUuid}/payments`, auth);
                if (payment && payments[0].id !== payment.id) {
                    paymentRef.current = payments[0];
                    return true;
                }
                return false;
            });

        payment = paymentRef.current;
        onPaymentProcessed();
        return result && paymentRef.current?.statusId == PaymentStatusId.Successful;
    }

    const onSubmit = async (event: any, cardDetails: IStripeCardDetails) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        setSubmitLoading(true);
        let cardNumberElement = elements.getElement(CardNumberElement);
        let cardExpiryElement = elements.getElement(CardExpiryElement);
        let cardCvcElement = elements.getElement(CardCvcElement);
        cardNumberElement?.update({disabled: true});
        cardExpiryElement?.update({disabled: true});
        cardCvcElement?.update({disabled: true});

        if (cardNumberElement) {
            cardDetails.card = cardNumberElement;
            try {
                if (!outstandingBalance()) {
                    if (await saveOnly(cardDetails)) {
                        navigateToBilling();
                        return true;
                    }
                } else {
                    if (await saveAndPay(cardDetails)) {
                        navigateToBilling();
                        return true;
                    }
                }
            } catch {
                setUnexpectedError();
            }
        }

        cardNumberElement?.update({disabled: false});
        cardExpiryElement?.update({disabled: false});
        cardCvcElement?.update({disabled: false});
        setSubmitLoading(false);
        return false;
    }

    const style = {
        style: {
            base: {
                fontSize: '16px',
                fontFamily: "'Noto Sans', sans-serif",
                iconColor: '#004b4e',
                '::placeholder': {
                    color: '#808080',
                },
                lineHeight: '2rem'
            }
        }
    }

    return (
        <div>
            <form onSubmit={methods.handleSubmit((data, event) => onSubmit(event, data))}>
                <div className="bg-super-light-grey w-full py-lg px-xl rounded-md">
                    <ElementsConsumer>
                        {({elements, stripe}) => (
                            <div>
                                <h3 className="mb-md">Card Details</h3>
                                <label className="text-p1" htmlFor="cardInfo"><b>Card Information</b></label>
                                <div id="cardInfo" className="bg-pure-white rounded-md outline outline-1 outline-primary-text mb-md">
                                    <div className="px-md border-b border-primary-text">
                                        <CardNumberElement id="card-number-element" options={style}/>
                                    </div>
                                    <div className="flex">
                                        <div className="basis-1/2 px-md border-r border-primary-text">
                                            <CardExpiryElement id="card-expiry-element" options={style}/>
                                        </div>
                                        <div className="basis-1/2 px-md">
                                            <CardCvcElement id="card-cvc-element" options={style}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </ElementsConsumer>
                    <label className="text-p1" htmlFor="nameOnCard"><b>Name on card</b></label>
                    <div id="nameOnCard" className="bg-pure-white rounded-md outline outline-1 outline-primary-text mb-md p-xs">
                        <input
                            {...methods.register("name", {required: true})}
                            className="focus:outline-0 w-full px-sm"
                            value={name}
                            onChange={() => setName}
                            type="text"
                            inputMode="text"
                            disabled={submitLoading}/>
                    </div>
                    <label className="text-p1" htmlFor="postcode"><b>Postcode</b></label>
                    <div id="postcode" className="bg-pure-white rounded-md outline outline-1 outline-primary-text mb-lg p-xs">
                        <input
                            {...methods.register("postcode", {required: true})}
                            className="focus:outline-0 w-full px-sm"
                            value={postcode}
                            onChange={() => setPostcode}
                            type="text"
                            inputMode="text"
                            disabled={submitLoading}/>
                    </div>
                    {
                        error && <p className="text-p2 text-error-red text-center mt-xs sm:max-w-sm mb-lg">{error}</p>
                    }
                    <div className="flex gap-md">
                        <button className="secondary" onClick={navigateToBilling}>Cancel</button>
                        <StateButton loading={submitLoading} submit={true}>
                            {outstandingBalance() ? "Save & Pay" : "Save"}
                        </StateButton>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default PaymentCard;