import React from 'react';
import Checked from "../../images/checked.svg";
import { useNavigate } from "react-router-dom";

function PaymentMethodAdded() {
    const navigate = useNavigate();

    const handleGoBackToBilling = () => {
        navigate('/billing');
    };

    return (
        <div className={'my-xl text-center'}>
            <div className={'w-full flex row justify-center mb-5'}>
                <img className="w-12 h-712 mt-3" src={Checked} alt="checked"/>
            </div>
            <h1 className={'text-h3 mb-4'}>Well done</h1>
            <p className={'mb-5 text-h4 font-bold'}>You have successfully registered your billing details.</p>
            <div>
                <button
                    className={'px-4 py-2 secondary'}
                    onClick={handleGoBackToBilling}
                >
                    Go back to billing
                </button>
            </div>
        </div>
    );
}

export default PaymentMethodAdded;