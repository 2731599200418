import React, {useState} from 'react';
import {User} from "./types";
import {useAuth} from "react-oidc-context";
import {getClaimValue} from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import {composeUrl} from "../../utils/api";
import OkCancelModal from "../Shared/Modal/OkCancelModal";

interface Props {
    user?: User,
    isOpen: boolean,
    setIsOpen: (value: boolean) => void,
    onConfirm: () => void
}

function DeleteUser({user, isOpen, setIsOpen, onConfirm}: Props) {
    const auth = useAuth();
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState(false);

    function cancel() {
        setError("");
        setIsOpen(false);
    }

    const deleteUser = async () => {
        setLoading(true);
        let orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);

        try {
            const response = await fetch(composeUrl(`organisations/${orgUuid}/administrators/${user?.id}`),
                {
                    headers: [['Authorization', `Bearer ${auth.user?.access_token}`]],
                    method: 'DELETE'
                });
            if (response.ok) {
                setIsOpen(false);
                onConfirm();
            } else {
                const responseBody = await response.json();
                if (responseBody.hasOwnProperty('code') && responseBody.hasOwnProperty('message')) {
                    setError(responseBody.message);
                } else {
                    setError("Sorry an error has occurred");
                }
            }
        } catch (error) {
            console.error(error);
            setError("Sorry an error has occurred");
        } finally {
            setLoading(false);
        }
    };

    return (
        <OkCancelModal
            title="Delete User"
            okLabel="Delete"
            okClick={loading ? () => {
            } : deleteUser}
            cancelLabel="Cancel"
            cancelClick={cancel}
            show={isOpen}
            errorMessage={error}
            important={true}
            isLoading={loading}>
            <div className="mb-xl">
                <p>Are you sure you want to delete <b>{`${user?.firstName} ${user?.lastName}`}</b>?</p>
                <p>This is a permanent action that cannot be undone</p>
            </div>
        </OkCancelModal>
    );
}

export default DeleteUser;