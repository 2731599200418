import React, { useState } from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context"
import '../styles/index.css';
import { claimValueContains, getClaimValue, hasRoleClaim } from "../utils/authUser";
import LimeClaimTypes from "../models/limeClaimTypes";
import UserType from "../models/userType";
import OrganisationUserType from "../models/organisationUserType";
import WellKnownRoles from "../models/wellKnownRoles";
import { User } from "oidc-client-ts";
import NavBar from "./Navigation";
import Footer from "./Footer";
import Header from "./Header";
import CookieBanner from "./Shared/CookieBanner/CookieBanner";
import WelcomeJourney from "./Welcome";
import { limeApiFetch, limeApiGet } from "../utils/api";
import { sleepFor } from "../utils/async";
import LoadingSpinner from "./Shared/LoadingSpinner/LoadingSpinner";

export interface GetUserTerms {
  accepted: boolean
}

function App() {
  const navigate = useNavigate();
  const auth = useAuth();
  const [acceptedTerms, setAcceptedTerms] = useState<boolean | null>(null);
  const duplicateSessionCheckFrequency = 60000 // 60 seconds

  async function acceptTerms() {
    for (let i = 0; i < 3; i++) {
      try {
        if (i > 0) {
          await sleepFor(i);
        }
        const orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
        const termsResponse = await limeApiFetch(`organisations/${orgUuid}/terms`, 'POST', auth);
        if (termsResponse.ok) {
          setAcceptedTerms(true);
          return true;
        } else {
          console.log("Accept terms failed due to unexpected response status: " + termsResponse.status);
        }
      } catch (e) {
        console.error("An error occurred while attempting to accept the terms", e);
      }
    }
    setAcceptedTerms(true); // Allow the user to continue
    return false;
  }

  function setSessionInfo() {
    if (auth.user && 'expires_at' in auth.user) {
      const sessionInfo = {
        expires_at: auth.user.expires_at,
        email: auth.user.profile.email
      };
      sessionStorage.setItem("sessionInfo", JSON.stringify(sessionInfo));
      localStorage.setItem("sessionInfo", JSON.stringify(sessionInfo));
    } else {
      console.error("Unable to set session info. User or expiration timestamp missing.");
    }
  }

  React.useEffect(() => {
    function checkLogoutSignal() {
      const localSessionInfo = JSON.parse(localStorage.getItem("sessionInfo") || '{}');
      const currentSessionInfo = JSON.parse(sessionStorage.getItem("sessionInfo") || '{}');
      const emailChanged = localSessionInfo.email !== currentSessionInfo.email;
      if (localSessionInfo.expires_at && currentSessionInfo.expires_at && localSessionInfo.expires_at > currentSessionInfo.expires_at && emailChanged) {
        window.sessionStorage.clear();
        window.location.reload();
      }
    }
    const intervalId = setInterval(checkLogoutSignal, duplicateSessionCheckFrequency);
    window.addEventListener("popstate", checkLogoutSignal);

    function handleStorageEvent(e: StorageEvent) {
      if (e.key === "sessionInfo") {
        checkLogoutSignal();
      }
    }
    window.addEventListener("storage", handleStorageEvent);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("popstate", checkLogoutSignal);
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, []);



  React.useEffect(() => {
    (async () => {
      if (auth.isLoading) {
        return;
      }

      if (auth.error) {
        await auth.signoutRedirect();
        return;
      }

      if (!auth.isAuthenticated) {
        await auth.signinRedirect({ state: window.location.pathname });
      }
      else if (window.location.pathname.startsWith('/impersonate/')) {
        navigate(window.location.pathname, { replace: true });
      }
      else {
        let currentSessionInfo = JSON.parse(sessionStorage.getItem("sessionInfo") || '{}');
        if (!currentSessionInfo.expires_at) {
          setSessionInfo();
        }

        let isOrganisationUser = (user: User | null | undefined) => {
          return getClaimValue(user, LimeClaimTypes.UserType) === UserType.Organisation &&
            getClaimValue(user, LimeClaimTypes.OrganisationUuid) &&
            claimValueContains(user, LimeClaimTypes.OrganisationUserType, OrganisationUserType.Admin)
        };

        let isGlobalAdmin = (user: User | null | undefined) => {
          return getClaimValue(user, LimeClaimTypes.UserType) === UserType.Lime &&
            hasRoleClaim(user, WellKnownRoles.LimeAdmin)
        };

        if (isOrganisationUser(auth.user) || isGlobalAdmin(auth.user)) {
          const orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
          if (!orgUuid) {
            navigate("/access-denied", { replace: true });
          }

          if (window.location.pathname === "" || window.location.pathname === "/") {
            navigate("/dashboard", { replace: true }); // Redirect straight into the dashboard when entering the default host url
          } else {
            navigate(window.location.pathname, { replace: true })
          }
        } else {
          navigate("/access-denied", { replace: true });
        }
      }
    })();
  }, [auth.isLoading]);

  React.useEffect(() => {
    (async () => {
      if (!auth.isLoading && auth.isAuthenticated) {
        const orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
        if (orgUuid) {
          try {
            const terms = await limeApiGet<GetUserTerms>(`organisations/${orgUuid}/terms`, auth);
            setAcceptedTerms(terms.accepted);
          } catch (error) {
            console.error("Failed to fetch user terms:", error);
            setAcceptedTerms(false);
          }
        }
      }
    })();
  }, [auth.isLoading, auth.isAuthenticated]);

  const impersonating = window.location.pathname.startsWith('/impersonate/');

  if (auth.isLoading || (!impersonating && acceptedTerms == null)) {
    return (
        <div className="my-xl">
            <LoadingSpinner loading={true}>&nbsp;</LoadingSpinner>
        </div>
    );
  }

  if (acceptedTerms || impersonating) {
    return (
        <div className="h-full">
          <CookieBanner/>
          <div className="relative bg-dark-grey container mx-auto grid grid-cols-1 sm:grid-rows-[5rem_auto_min-content]
            sm:grid-cols-[16.25rem_1fr] sm:min-h-full">
            <header className="bg-dark-grey hidden sm:px-md sm:col-start-2 sm:flex items-center h-[5rem]">
              <Header/>
            </header>
            <nav className="bg-pure-white px-md py-sm sm:row-start-1 sm:row-span-2 border-b-[1px] border-b-light-grey
              sm:border-0">
              <NavBar/>
            </nav>
            <main className="bg-background-blu p-md sm:p-xl sm:col-start-2 min-h-[25rem]">
              <Outlet/>
            </main>
            <footer className="sm:col-span-2 min-h-[5rem]">
              <Footer/>
            </footer>
          </div>
        </div>
    )
  } else {
    return (
        <div>
          <CookieBanner/>
          <WelcomeJourney onAcceptTerms={acceptTerms}/>
        </div>
    )
  }
}

export default App;