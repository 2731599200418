import React, { useState } from "react";
import { ScrollRestoration } from "react-router-dom";
import WelcomeAvatar from "../../images/welcome-avatar.svg";
import { WelcomeJourneyProps } from "./WelcomeJourney";
import ButtonSpinner, {SpinnerTheme} from "../Shared/StateButton/ButtonSpinner";

function WelcomeGetStarted(props: WelcomeJourneyProps) {
  const termsUrl = "https://lime-public.s3.eu-west-1.amazonaws.com/web/Organisations/Documents/OrganisationTermsAndConditions.pdf";
  const privacyUrl = "https://lime-public.s3.eu-west-1.amazonaws.com/web/Organisations/Documents/OrganisationPrivacyPolicy.pdf";
  const faqsUrl = "https://lime-public.s3.eu-west-1.amazonaws.com/web/Organisations/Documents/Organisation+FAQ.pdf";
  const [acceptingTerms, setAcceptingTerms] = useState(false);

  async function acceptAndContinue() {
    setAcceptingTerms(true);
    await props.onAcceptTerms();
    setAcceptingTerms(false);
  }

  return (
    <main className="text-center bg-pure-white px-md sm:px-auto py-md
        col-start-1 col-span-4 sm:col-span-12 row-start-2 row-span-1">
      <div className="sm:mx-auto sm:grid sm:grid-cols-[2fr_1fr]">
        <div className="sm:col-start-1 sm:p-md md:p-xl">
          <section className="mb-xl px-sm text-deep-blue text-left">
            <h4 className="text-p1 text-bold text-left sm:text-h4 sm:font-normal text-deep-blue mb-lg">
              We are so pleased you’ve joined us. And your employees will be too.
            </h4>
            <h4 className="text-p1 text-bold text-left sm:text-h4 sm:font-normal text-deep-blue mb-lg">
              Our purpose is to make all employees healthier and happier. We also try to make employee benefits as simple as possible, for everyone.
            </h4>
            <b>Lime OnCall. Simple, confidential, zero faff.</b>
          </section>
          <section className="text-center font-normal mb-xl text-deep-blue">
            <p className="text-p1 sm:text-p1 font-bold sm:font-bold mb-md">Terms</p>
            <div className="mb-md">
              <label className="sm:text-p1 sm:font-normal" htmlFor="accept-terms">
                I have read and agree to the<br/>
                <a href={termsUrl} target="_blank">Terms and Conditions</a> and&nbsp;
                <a href={privacyUrl} target="_blank">Privacy Policy</a>
              </label>
            </div>
            <button className="font-sans-co bg-deep-blue" onClick={acceptAndContinue} disabled={acceptingTerms}>
              <ButtonSpinner enabled={acceptingTerms} theme={SpinnerTheme.Light}/>
              Continue
            </button>
            <p className="sm:text-p1 sm:font-normal mt-md">
              More questions? You can find our <a href={faqsUrl} target="_blank">FAQs</a> here.
            </p>
          </section>
        </div>
        <div className="flex justify-center sm:col-start-2 ">
          <img src={WelcomeAvatar} alt="Welcome Avatar" className="my-xl sm:my-md sm:float-right sm:mt-4xl sm:h-64"/>
        </div>
      </div>
      <ScrollRestoration />
  </main>
  );
}

export default WelcomeGetStarted;