import React, {useState} from 'react';
import Tab from "./Tab";
import TabContent from "./TabContent";

interface TabsProps {
    children: JSX.Element[];
}

function Tabs(props: TabsProps) {
    const [selectedTab, setSelectedTab] = useState<string>();

    let tabs = props.children.filter(c => c.props.tabKey);
    let tabContent = props.children.filter(c => c.props.associatedTabKey);

    React.useEffect(() => {
        if (tabs.length > 0) {
            let activeTabs = tabs.filter(t => !t.props.disabled);
            if (activeTabs.length > 0) {
                setSelectedTab(activeTabs[0].props.tabKey);
            } else {
                setSelectedTab(tabs[0].props.tabKey);
            }
        }
    }, []);

    const tabSelected = (tabKey: string) => {
        setSelectedTab(tabKey);
    }

    const generateTabs = tabs.map((t, i) =>
        <Tab
            key={i}
            name={t.props.name}
            tabKey={t.props.tabKey}
            tabSelected={() => { tabSelected(t.props.tabKey) }}
            disabled={t.props.disabled}
            selectedTabKey={selectedTab}/>
    );

    const generateTabContent = tabContent.map((tc, i) =>
        <TabContent
            key={i}
            associatedTabKey={tc.props.associatedTabKey}
            selectedTabKey={selectedTab}>
            {tc.props.children}
        </TabContent>
    );

    return (
        <div>
            <div className="flex flex-row items-start bg-pure-white">
                {
                    generateTabs
                }
            </div>
            <div>
                {
                    generateTabContent
                }
            </div>
        </div>
    )
}

export default Tabs;