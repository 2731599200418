import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {composeUrl} from "../../utils/api";
import {getClaimValue} from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import {useAuth} from "react-oidc-context";
import {MemberFileStatusId} from "./Members";
import OkCancelModal from "../Shared/Modal/OkCancelModal";
import LoadingSpinner from "../Shared/LoadingSpinner/LoadingSpinner";
import MemberFileChanges from "./MemberFileChanges";

export interface ErrorDetails {
    id: number,
    message: string
}

export interface MemberDetails {
    memberId: number,
    externalId: number,
    firstName: string,
    lastName: string,
    email: string,
    dateOfBirth: Date
}

export interface MemberFileDetails {
    id: number,
    filename: string,
    statusId: MemberFileStatusId,
    uploadedOn: Date,
    uploadedByUser: string,
    reviewedOn: Date,
    reviewedByUser: string,
    new: MemberDetails[],
    updated: MemberDetails[],
    removed: MemberDetails[],
    unchanged: MemberDetails[],
    errors: ErrorDetails[]
}

function Review() {
    const auth = useAuth();
    const navigate = useNavigate();
    const params = useParams();

    const [memberFileDetails, setMemberFileDetails] = useState<MemberFileDetails>();
    const [showConfirmFileModal, setShowConfirmFileModal] = useState<boolean>(false);
    const [showAbandonFileModal, setShowAbandonFileModal] = useState<boolean>(false);
    const [confirmFileError, setConfirmFileError] = useState<string>("");
    const [abandonFileError, setAbandonFileError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);

    React.useEffect(() => {
        let memberFileId = params["memberFileId"];
        let orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
        fetch(composeUrl(`organisations/${orgUuid}/memberFiles/${memberFileId}`),
            {
                headers: [[ 'Authorization',  `Bearer ${auth.user?.access_token}` ]],
                method: 'GET'
            })
            .then(response => response.json() as Promise<MemberFileDetails>)
            .then(data => {
                setMemberFileDetails(data);
                setLoading(false);
            });
    }, [params]);

    const onConfirmFileClicked = () => {
        setConfirmFileError("");
        setShowConfirmFileModal(true);
    }

    const onAbandonFileClicked = () => {
        setAbandonFileError("");
        setShowAbandonFileModal(true);
    }

    const onConfirmFile = () => {
        setConfirmFileError("");
        let orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
        fetch(composeUrl(`organisations/${orgUuid}/memberFiles/${memberFileDetails?.id}/confirm`),
            {
                headers: [[ 'Authorization',  `Bearer ${auth.user?.access_token}` ]],
                method: 'POST'
            })
            .then(response => {
                if (response.ok) {
                    setShowConfirmFileModal(false);
                    navigate("/members");
                } else {
                    setConfirmFileError("Sorry an error occurred and the file could not be confirmed");
                }
            });
    }

    const onAbandonFile = () => {
        setConfirmFileError("");
        let orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
        fetch(composeUrl(`organisations/${orgUuid}/memberFiles/${memberFileDetails?.id}/abandon`),
            {
                headers: [[ 'Authorization',  `Bearer ${auth.user?.access_token}` ]],
                method: 'POST'
            })
            .then(response => {
                if (response.ok) {
                    setShowAbandonFileModal(false);
                    navigate("/members");
                } else {
                    setAbandonFileError("Sorry an error occurred and the file could not be abandoned");
                }
            });
    }

    const onCancelConfirmFile = () => {
        setConfirmFileError("");
        setShowConfirmFileModal(false);
    }

    const onCancelAbandonFile = () => {
        setAbandonFileError("");
        setShowAbandonFileModal(false);
    }

    const getUsersLabel = (userCount: number): string => {
        return `${userCount} User${(userCount === 0 || userCount > 1) ? "s" : ""}`;
    }

    const confirmModalContent = () => {
        return (
            <div>
                <p>Are you sure you want to confirm the changes in this file?</p>
                {modalChangesContent()}
            </div>
        );
    }

    const abandonModalContent = () => {
        return (
            <div>
                <p>Are you sure you want to abandon the changes in this file?</p>
                {modalChangesContent()}
            </div>
        );
    }

    const modalChangesContent = () => {
        return (
            <div>
                <div className="flex items-center justify-center">
                    <div className="my-xl">
                        <div className="flex flex-row">
                            <div className="flex flex-col text-left mx-md">
                                <div className="px-sm my-sm">
                                    <h4><b>NEW</b></h4>
                                    <p>{getUsersLabel(memberFileDetails?.new.length ?? 0)}</p>
                                </div>
                                <div className="px-sm my-sm">
                                    <h4><b>REMOVED</b></h4>
                                    <p>{getUsersLabel(memberFileDetails?.removed.length ?? 0)}</p>
                                </div>
                            </div>
                            <div className="flex flex-col text-left mx-md">
                                <div className="px-sm my-sm">
                                    <h4><b>UPDATED</b></h4>
                                    <p>{getUsersLabel(memberFileDetails?.updated.length ?? 0)}</p>
                                </div>
                                <div className="px-sm my-sm">
                                    <h4><b>UNCHANGED</b></h4>
                                    <p>{getUsersLabel(memberFileDetails?.unchanged.length ?? 0)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p className="mb-lg">Once these changes have been applied to your members they cannot be undone.</p>
            </div>
        );
    }

    return (
        <div className="grid grid-cols-4 grid-rows-auto mb-2xl">
            <OkCancelModal
                title="Confirm Member File"
                okLabel="Confirm File"
                okClick={onConfirmFile}
                cancelLabel="Cancel"
                cancelClick={onCancelConfirmFile}
                show={showConfirmFileModal}
                errorMessage={confirmFileError}>
                {confirmModalContent()}
            </OkCancelModal>
            <OkCancelModal
                title="Abandon Member File"
                okLabel="Abandon File"
                okClick={onAbandonFile}
                cancelLabel="Cancel"
                cancelClick={onCancelAbandonFile}
                show={showAbandonFileModal}
                errorMessage={abandonFileError}>
                {abandonModalContent()}
            </OkCancelModal>
            <div className="col-span-2 items-start mb-xl">
                <h1 className="mb-xl">Members</h1>
                <h2 className="mb-xl">Review File</h2>
                <p>Please review the changes detected in the file which were automatically cross referenced against your existing members.</p><br/>
                <p>If you're happy with the changes you can <b>confirm</b> the file to apply them, otherwise select <b>abandon</b> and upload another file.</p>
            </div>
            <div className="col-span-4 items-start mb-xl">
                <LoadingSpinner loading={loading}>
                    <div>
                        <MemberFileChanges memberFileDetails={memberFileDetails}/>
                        <div className="mt-lg">
                            <button
                                className="bg-pure-white border-solid border border-primary-text rounded-full text-primary-text font-sans-co mr-md"
                                disabled={memberFileDetails?.statusId != MemberFileStatusId.Review}
                                onClick={onAbandonFileClicked}>
                                Abandon File
                            </button>
                            <button
                                className="font-sans-co"
                                disabled={memberFileDetails?.statusId != MemberFileStatusId.Review}
                                onClick={onConfirmFileClicked}>
                                Confirm File
                            </button>
                        </div>
                    </div>
                </LoadingSpinner>
            </div>
        </div>
    );
}

export default Review;