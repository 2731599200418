import React, { useState } from 'react';
import { useAuth } from "react-oidc-context";
import { composeUrl } from "../../utils/api";
import Shield from "../../images/shield-03.svg";
import Mail from "../../images/mail-02.svg";
import LockClosed from "../../images/lock-01.svg";
import LockOpen from "../../images/lock-unlocked-03.svg";
import Trash from "../../images/trash-01.svg";
import DropdownMenu from "../Shared/DropdownMenu";
import DropdownMenuItem from "../Shared/DropdownMenu/DropdownMenuItem";
import DeleteUser from "./DeleteUser";
import { User } from "./types";
import {getClaimValue, hasRoleClaim} from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import AddUser from "./AddUser";
import ModifyUserRoles from "./ModifyUserRoles";
import ChangeUserEmail from "./ChangeUserEmail";
import ChangeUserAccess from "./ChangeUserAccess";
import WellKnownRoles from "../../models/wellKnownRoles";
import {useNavigate} from "react-router-dom";

function Users() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = React.useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = React.useState(false);
  const [isModifyUserRolesModalOpen, setIsModifyUserRolesModalOpen] = React.useState(false);
  const [isChangeUserEmailModalOpen, setIsChangeUserEmailModalOpen] = React.useState(false);
  const [isChangeUserAccessModalOpen, setIsChangeUserAccessModalOpen] = React.useState(false);

  let closeCallbacks: any = [];
  function closeAllDropdownMenus() {
    for(const close of closeCallbacks) {
      close();
    }
  }

  function openChangeRolesModal(user: User) {
    closeAllDropdownMenus();
    setSelectedUser(user);
    setIsModifyUserRolesModalOpen(true);
  }

  function openChangeEmailModal(user: User) {
    closeAllDropdownMenus();
    setSelectedUser(user);
    setIsChangeUserEmailModalOpen(true);
  }

  function openChangeAccessModal(user: User) {
    closeAllDropdownMenus();
    setSelectedUser(user);
    setIsChangeUserAccessModalOpen(true);
  }

  function openDeleteUserModal(user: User) {
    closeAllDropdownMenus();
    setSelectedUser(user);
    setIsDeleteUserModalOpen(true);
  }

  function shortenAdminRoleName(role: string): string {
    return role.replace("Organisation ", "");
  }

  function refresh() {
    let orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
    fetch(composeUrl(`organisations/${orgUuid}/administrators`),
      {
        headers: [['Authorization', `Bearer ${auth.user?.access_token}`]],
        method: 'GET'
      })
      .then(response => response.json() as Promise<User[]>)
      .then(data => setUsers(data) );
  }

  React.useEffect(() => {
    if (!hasRoleClaim(auth.user, WellKnownRoles.OrganisationUserAdmin)) {
      navigate('/access-denied');
    }

    refresh()
  }, []);

  return (
    <div className="w-full">
      <h1 className="mb-lg">Users</h1>
      <h3 className="mb-md">Manage</h3>
      <button className="bg-button-background-blue border-button-background-blue hover:underline font-bold font-sans-co text-primary-text rounded-md mb-4" onClick={() => setIsAddUserModalOpen(true)}>Add User</button>
      <p>Use this area to manage staff access at your organisation</p>
      <table className="w-full mt-md border-separate border-spacing-x-0 border-spacing-y-2">
        <thead className="bg-super-light-grey">
          <tr className="h-2xl text-left text-primary-text">
            <th className="px-md py-sm border-b-2 border-primary-text rounded-lt-md"><b>First Name</b></th>
            <th className="px-md py-sm border-b-2 border-primary-text">Last Name</th>
            <th className="px-md py-sm border-b-2 border-primary-text">Email</th>
            <th className="px-md py-sm border-b-2 border-primary-text">Roles</th>
            <th className="px-md py-sm border-b-2 border-primary-text">Status</th>
            <th className="px-md py-sm border-b-2 border-primary-text rounded-rt-md"><b>Actions</b></th>
          </tr>
        </thead>
        <tbody>
        {users.length > 0 && users.map((user, index) =>
          <tr key={index} className="text-left bg-pure-white even:bg-super-light-grey h-2xl">
            <td className="px-md py-sm rounded-l-md">{user.firstName}</td>
            <td className="px-md py-sm">{user.lastName}</td>
            <td className="px-md py-sm">{user.email}</td>
            <td className="px-md py-sm">
              {user.roles.length > 0 && user.roles.map((role, ri) =>
                <p key={ri} className="whitespace-nowrap">{shortenAdminRoleName(role)}</p>
              )}
              </td>
            <td className="px-md py-sm">{user.accessDisabled ? "Disabled" : "Enabled"}</td>
            <td className="px-md py-sm rounded-r-md">
              <DropdownMenu close={ (close: () => void) => { closeCallbacks[index] = close; } }>
                { auth.user?.profile.sub !== user.id && <DropdownMenuItem title="Change roles" icon={Shield} onClick={() => openChangeRolesModal(user)} /> }
                <DropdownMenuItem title="Change email" icon={Mail} onClick={() => openChangeEmailModal(user)} />
                { auth.user?.profile.sub !== user.id && <DropdownMenuItem title={`${user.accessDisabled ? "Enable" : "Disable"} access`} icon={user.accessDisabled ? LockOpen : LockClosed} onClick={() => openChangeAccessModal(user) } /> }
                { auth.user?.profile.sub !== user.id && <DropdownMenuItem title="Delete" icon={Trash} onClick={() => openDeleteUserModal(user) } /> }
              </DropdownMenu>
            </td>
          </tr>
        )}
        </tbody>
      </table>
      <AddUser isOpen={isAddUserModalOpen} setIsOpen={setIsAddUserModalOpen} onAdd={() => refresh() } />
      <DeleteUser user={selectedUser} isOpen={isDeleteUserModalOpen} setIsOpen={setIsDeleteUserModalOpen} onConfirm={() => refresh() } />
      <ModifyUserRoles user={selectedUser} isOpen={isModifyUserRolesModalOpen} setIsOpen={setIsModifyUserRolesModalOpen} onModifyRoles={() => refresh() } />
      <ChangeUserEmail user={selectedUser} isOpen={isChangeUserEmailModalOpen} setIsOpen={setIsChangeUserEmailModalOpen} onChangeEmail={() => refresh() } />
      <ChangeUserAccess user={selectedUser} isOpen={isChangeUserAccessModalOpen} setIsOpen={setIsChangeUserAccessModalOpen} onChangeAccess={() => refresh() } />
    </div>
  );
}

export default Users;