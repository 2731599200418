import React, { useState } from 'react';
import UserDashboard from "./UserDashboard";
import OnboardingDashboard from "./OnboardingDashboard";
import {limeApiGet} from "../../utils/api";
import {OnboardingStatus} from "./types";
import {useAuth} from "react-oidc-context";
import {getClaimValue} from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import LoadingSpinner from "../Shared/LoadingSpinner/LoadingSpinner";

function Dashboard() {
  const [viewUserDashboard, setViewUserDashboard] = useState<boolean>();
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(true);

  React.useEffect(() => {
    (async () => {
      const orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
      const data = await limeApiGet<OnboardingStatus>(`organisations/${orgUuid}/onboarding`, auth);
      let allComplete = true;
      for (let i = 0; i < data.tasks.length; i++) {
        if (!data.tasks[i].isComplete) {
          allComplete = false;
          break;
        }
      }
      setViewUserDashboard(allComplete);
      setLoading(false);
    })();
  }, [auth.user]);

  return (
      <LoadingSpinner loading={loading}>
        { !loading && viewUserDashboard &&
            <UserDashboard />
        }
        { !loading && !viewUserDashboard &&
            <>
                <OnboardingDashboard />
                <button className="mt-md font-sans-co w-fit px-10" onClick={() => setViewUserDashboard(true)}>
                    Continue to my dashboard
                </button>
            </>
        }
      </LoadingSpinner>
    );
}

export default Dashboard;