import React, {useState} from 'react';
import {MemberDetails, MemberFileDetails} from "./Review";
import {Tab, TabContent, Tabs} from "../Shared/Tabs";
import {formatViewDate} from "../../utils/dateFormat";

interface Props {
    memberFileDetails: MemberFileDetails | undefined
}

function MemberFileChanges(props: Props) {
    const [newTablePage, setNewTablePage] = useState<number>(1);
    const [newMaxTablePage, setNewMaxTablePage] = useState<number>(1);

    const [updatedTablePage, setUpdatedTablePage] = useState<number>(1);
    const [updatedMaxTablePage, setUpdatedMaxTablePage] = useState<number>(1);

    const [removedTablePage, setRemovedTablePage] = useState<number>(1);
    const [removedMaxTablePage, setRemovedMaxTablePage] = useState<number>(1);

    const [unchangedTablePage, setUnchangedTablePage] = useState<number>(1);
    const [unchangedMaxTablePage, setUnchangedMaxTablePage] = useState<number>(1);

    const paginationLimit = 25;

    React.useEffect(() => {
        if (props.memberFileDetails) {
            setNewMaxTablePage(Math.ceil(props.memberFileDetails.new.length / paginationLimit));
            setUpdatedMaxTablePage(Math.ceil(props.memberFileDetails.updated.length / paginationLimit));
            setRemovedMaxTablePage(Math.ceil(props.memberFileDetails.removed.length / paginationLimit));
            setUnchangedMaxTablePage(Math.ceil(props.memberFileDetails.unchanged.length / paginationLimit));
        }
    }, [props.memberFileDetails]);

    const getMemberDetails = (section: string): MemberDetails[] | undefined => {
        switch (section.toLowerCase()) {
            case "new":
                return props.memberFileDetails?.new;
            case "updated":
                return props.memberFileDetails?.updated;
            case "removed":
                return props.memberFileDetails?.removed;
            case "unchanged":
                return props.memberFileDetails?.unchanged;
            default:
                break;
        }
    }

    function previousPage(section: string) {
        switch (section) {
            case "new":
                setNewTablePage(newTablePage - 1);
                break;
            case "updated":
                setUpdatedTablePage(updatedTablePage - 1);
                break;
            case "removed":
                setRemovedTablePage(removedTablePage - 1);
                break;
            case "unchanged":
                setUnchangedTablePage(unchangedTablePage - 1);
                break;
            default:
                break;
        }
    }

    function nextPage(section: string) {
        switch (section) {
            case "new":
                setNewTablePage(newTablePage + 1);
                break;
            case "updated":
                setUpdatedTablePage(updatedTablePage + 1);
                break;
            case "removed":
                setRemovedTablePage(removedTablePage + 1);
                break;
            case "unchanged":
                setUnchangedTablePage(unchangedTablePage + 1);
                break;
            default:
                break;
        }
    }

    function getTablePage(section: string): number {
        switch (section) {
            case "new":
                return newTablePage;
            case "updated":
                return updatedTablePage;
            case "removed":
                return removedTablePage;
            case "unchanged":
                return unchangedTablePage;
            default:
                return 1;
        }
    }

    function getMaxTablePage(section: string) {
        switch (section) {
            case "new":
                return newMaxTablePage;
            case "updated":
                return updatedMaxTablePage;
            case "removed":
                return removedMaxTablePage;
            case "unchanged":
                return unchangedMaxTablePage;
            default:
                return 1;
        }
    }

    const getTable = (section: string) => {
        let data = getMemberDetails(section);
        return (
            <div>
                <table className="w-full">
                    <thead className="bg-super-light-grey">
                    <tr className="h-2xl text-left text-primary-text">
                        <th className="px-md py-sm border-b-2 border-primary-text rounded-l-md"><b>ID</b></th>
                        <th className="px-md py-sm border-b-2 border-primary-text"><b>First Name</b></th>
                        <th className="px-md py-sm border-b-2 border-primary-text"><b>Last Name</b></th>
                        <th className="px-md py-sm border-b-2 border-primary-text"><b>Email</b></th>
                        <th className="px-md py-sm border-b-2 border-primary-text rounded-r-md"><b>Date of Birth</b></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data?.slice((getTablePage(section) - 1) * paginationLimit, getTablePage(section) * paginationLimit).map((member, i) =>
                            <tr key={i} className="text-left bg-pure-white even:bg-super-light-grey h-xl">
                                <td className="px-md py-sm border-y-2 border-background-blu rounded-l-md">{member.externalId}</td>
                                <td className="px-md py-sm border-y-2 border-background-blu">{member.firstName}</td>
                                <td className="px-md py-sm border-y-2 border-background-blu">{member.lastName}</td>
                                <td className="px-md py-sm border-y-2 border-background-blu">{member.email}</td>
                                <td className="px-md py-sm border-y-2 border-background-blu rounded-r-md">{formatViewDate(member.dateOfBirth)}</td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
                <div className="flex flex-row mt-lg ml-md">
                    {
                        getTablePage(section) > 1 ?
                            <a className="hover:cursor-pointer text-mid-grey mr-md" onClick={() => previousPage(section)}>{"< Previous"}</a> :
                            <p className="text-light-grey mr-md">{"< Previous"}</p>
                    }
                    {
                        getTablePage(section) < getMaxTablePage(section) ?
                            <a className="hover:cursor-pointer text-mid-grey" onClick={() => nextPage(section)}>{"Next >"}</a> :
                            <p className="text-light-grey">{"Next >"}</p>
                    }
                </div>
            </div>
        )
    }

    return (
        <div>
            <Tabs>
                <Tab
                    name={`New (${props.memberFileDetails?.new.length ?? 0})`}
                    tabKey="1"
                    disabled={(props.memberFileDetails?.new.length ?? 0) === 0}/>
                <Tab
                    name={`Updated (${props.memberFileDetails?.updated.length ?? 0})`}
                    tabKey="2"
                    disabled={(props.memberFileDetails?.updated.length ?? 0) === 0}/>
                <Tab
                    name={`Removed (${props.memberFileDetails?.removed.length ?? 0})`}
                    tabKey="3"
                    disabled={(props.memberFileDetails?.removed.length ?? 0) === 0}/>
                <Tab
                    name={`Unchanged (${props.memberFileDetails?.unchanged.length ?? 0})`}
                    tabKey="4"
                    disabled={(props.memberFileDetails?.unchanged.length ?? 0) === 0}/>
                <TabContent associatedTabKey="1">
                    { getTable("new") }
                </TabContent>
                <TabContent associatedTabKey="2">
                    { getTable("updated") }
                </TabContent>
                <TabContent associatedTabKey="3">
                    { getTable("removed") }
                </TabContent>
                <TabContent associatedTabKey="4">
                    { getTable("unchanged") }
                </TabContent>
            </Tabs>
        </div>
    );
}

export default MemberFileChanges;