import React, {useRef, useState} from 'react';
import AlertCircle from "../../images/alert-circle.svg";
import {Payment, PaymentFailureReasonId, PaymentRetryInitialised, PaymentStatusId} from "./types";
import {confirmPaymentWithExistingCard} from "./StripePaymentService";
import {getClaimValue} from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import {checkStatusBackoff, limeApiGet, limeApiPost} from "../../utils/api";
import {useAuth} from "react-oidc-context";
import LoadingSpinner from "../Shared/LoadingSpinner/LoadingSpinner";
import {Stripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";

interface Props {
  stripe: Stripe | null,
  onPaymentLoaded?: (payment: Payment | null) => void,
  onPaymentProcessed?: (payment: Payment) => void
}

function PaymentFailureBanner({stripe, onPaymentLoaded, onPaymentProcessed}: Props) {
  const auth = useAuth();
  const [payment, setPayment] = useState<Payment | null>(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const paymentRef = useRef(payment);

  React.useEffect(() => {
    (async () => {
      let payment = await getLatestPayment();
      setPayment(payment);
      if (onPaymentLoaded) {
        onPaymentLoaded(payment);
      }
    })();
  }, [])

  async function getLatestPayment() {
    const orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
    const payments = await limeApiGet<Payment[]>(`organisations/${orgUuid}/payments`, auth);
    return payments && payments.length > 0 ? payments[0] : null;
  }

  function displayAmount() {
    return payment?.amount.toLocaleString('en-GB', { style: 'currency', currency: 'GBP'});
  }

  function setUnexpectedError() {
    setErrorMessage("Sorry an error has occurred");
  }

  const click = async (event: any) => {
    event.preventDefault();
    if (!stripe || !payment) {
      return;
    }

    setLoading(true);
    setErrorMessage(undefined);

    try {
      let orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
      const retryResponse = await limeApiPost<PaymentRetryInitialised>(
        `organisations/${orgUuid}/payments/${payment.id}/retry`,
        auth);
      const stripeResponse = await confirmPaymentWithExistingCard(stripe, retryResponse.stripeClientSecret);
      if (stripeResponse.error) {
        if (stripeResponse.error.code == "payment_intent_authentication_failure") {
          setErrorMessage("Authentication failed");
          setLoading(false);
          return;
        }
        setErrorMessage(stripeResponse.error.message);
      }
      const result = await checkStatusBackoff(
        async () => {
          let latestPayment = await getLatestPayment();
          if (latestPayment && latestPayment.id !== payment.id) {
            paymentRef.current = latestPayment;
            return true;
          }
          return false;
        });
      if (result) {
        if (onPaymentProcessed && paymentRef.current) {
          setPayment(paymentRef.current);
          onPaymentProcessed(paymentRef.current);
        }
        return true;
      }
      setUnexpectedError();
    } catch (e) {
      setUnexpectedError();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      { stripe && payment && payment.statusId == PaymentStatusId.Failed && (
        <Elements stripe={stripe}>
          <div className="mb-xl">
            <LoadingSpinner loading={loading}>
              <div className="bg-action-required rounded-lg p-md">
                <div className="flex justify-start">
                  <img className="w-10 h-10 my-auto" src={AlertCircle} alt="alert"/>
                  <div className="mx-lg">
                    <h4>Action Required</h4>
                    <p>
                      {payment.failureReasonId == PaymentFailureReasonId.AuthenticationRequired
                        ? (<>
                          We were unable to process our most recent payment attempt of {displayAmount()} on card
                          ending {payment.paymentMethodLast4Digits} because your bank has requested
                          authentication. Please provide authentication to resolve the issue.
                        </>)
                        : (<>
                          Your bank has declined our most recent payment attempt of {displayAmount()} on card
                          ending {payment.paymentMethodLast4Digits} with the reason
                          “{payment.displayFailureReason}”. You can either retry the payment with your current payment
                          method or change your payment method below.
                        </>)
                      }
                    </p>
                    {
                      errorMessage && <p className="text-error-red mt-md">{errorMessage}</p>
                    }
                  </div>
                  <div className="my-auto">
                    <button onClick={click} className="py-sm px-xl w-fit font-bold text-primary-text bg-pure-white rounded-md
                                    border border-solid border-error-red">
                      {payment.failureReasonId == PaymentFailureReasonId.AuthenticationRequired
                        ? "Authenticate"
                        : "Retry"}
                    </button>
                  </div>
                </div>
              </div>
            </LoadingSpinner>
          </div>
        </Elements>
      )}
    </>
  );
}

export default PaymentFailureBanner;