import React from 'react';
import Breadcrumb from "../Shared/Breadcrumb/Breadcrumb";

function Header() {
  return (
    <div>
      <Breadcrumb />
    </div>
  );
}

export default Header;