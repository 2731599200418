import React, { useState } from 'react';
import FooterLink from "./FooterLink";
import Logo from '../../images/lime-light.svg';

function Footer() {
  const [currentYear] = useState(() =>  new Date().getFullYear());

  return (
    <div className="bg-dark-grey text-pure-white p-lg">
      <div className="flex flex-col sm:flex-row w-[10.625rem] sm:w-full sm:max-w-screen-md mx-auto gap-lg
        sm:justify-between sm:px-xl sm:py-lg">
        <nav>
          <h4 className="font-sans-co font-bold text-coh text-h2 mt-0">Company</h4>
          <FooterLink href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Organisations/Documents/AboutLimeOrg+2.0.pdf">
            About
          </FooterLink>
          <FooterLink href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Organisations/Documents/Organisation+FAQ.pdf">
            FAQ
          </FooterLink>
          <FooterLink href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Company/Documents/OnCallAccessibilityStatement.pdf">
            Accessibility Statement
          </FooterLink>
          <FooterLink href="mailto:complaints@limehealth.uk">
            Complaints
          </FooterLink>
        </nav>
        <nav>
          <h4 className="font-sans-co font-bold text-coh text-h2 mt-0">Legal</h4>
          <FooterLink href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Organisations/Documents/OrganisationTermsAndConditions.pdf">
            Terms and Conditions
          </FooterLink>
          <FooterLink href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/OnCallCookiePolicy.pdf">
            Cookies
          </FooterLink>
          <FooterLink href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Organisations/Documents/OrganisationPrivacyPolicy.pdf">
            Privacy Policy
          </FooterLink>
          <FooterLink href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Company/Documents/OnCallSecurityStatement.pdf">
            Security Policy
          </FooterLink>
        </nav>
        <section>
          <img src={Logo} alt="logo" className="h-[1.8125rem] my-md sm:mt-[0.4375rem] sm:mb-md" />
          <div className="trustpilot"></div>
          <p className="text-p2 mb-md">© {currentYear} Lime Global</p>
          <p className="text-p2 my-md">All Rights Reserved</p>
        </section>
      </div>
    </div>
  );
}

export default Footer;