import React, { useState } from 'react';
import Token from "./Token";
import { useLocation } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { DashboardData, DashboardDataGroup, OnboardingStatus, OnboardingTask } from "./types";
import { getClaimValue } from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import { composeUrl } from "../../utils/api";
import { formatViewDate } from "../../utils/dateFormat";
import OnboardingTaskItem from "./OnboardingTaskItem";

function UserDashboard() {
  const auth = useAuth();
  const [name, setName] = useState<string | undefined>();
  const [groups, setGroups] = useState<DashboardDataGroup[]>([]);

  React.useEffect(() => {
    setName(getClaimValue(auth.user, "nickname"));

    const orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
    fetch(composeUrl(`organisations/${orgUuid}/dashboard`),
      {
        headers: [['Authorization', `Bearer ${auth.user?.access_token}`]],
        method: 'GET'
      })
      .then(response => response.json() as Promise<DashboardData>)
      .then(data => {
        setGroups(data.groups);
      } );
  }, [auth.user]);

  return (
    <div>
      <h2 className="lg:text-h1">Hello {name}</h2>
      <p className="my-4 lg:text-h4 lg:font-normal">Here's what's happening in your organisation today</p>

      { groups && groups.map((group, gIndex) =>
        <div key={gIndex} className="flex gap-4 lg:gap-8 flex-wrap xl:flex-nowrap mb-8">
          { group.items.map((item, iIndex) =>
            <div key={iIndex} className="p-4 lg:p-8 bg-super-light-grey rounded-xl">
              <div className="lg:min-w-[8rem]">
                <h3 className="lg:text-h2 mb-2">{ item.label === "Start Date"
                  ? formatViewDate(new Date(item.value))
                  : item.value}</h3>
                <p className="lg:text-h4 lg:font-normal whitespace-nowrap">{item.label}</p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
    );
}

export default UserDashboard;
